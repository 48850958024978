import awardSilver from '../assets/images/awards/bitters/international-21/award-silver.webp'

import wscGold from '../assets/images/awards/liqeuers/san-fransisco-wsc/wsc-gold.png'
import wscSingleGold from '../assets/images/awards/liqeuers/san-fransisco-wsc/wsc-singlegold.png'

import ssGold from '../assets/images/awards/liqeuers/spiritselection/ss-gold.png'
import ssSilver from '../assets/images/awards/liqeuers/spiritselection/ss-silver.png'

import iwscSilver from '../assets/images/awards/liqeuers/iwsc/iwsc-silver.png'
import iwscBronze from '../assets/images/awards/liqeuers/iwsc/iwsc-bronze.png'

import lscSilver from '../assets/images/awards/liqeuers/lsc/lsc-silver.png'
import lscBronze from '../assets/images/awards/liqeuers/lsc/lsc-bronze.png'

import bartenderGold from '../assets/images/awards/liqeuers/bartender/bartender-gold.png'
import bartenderSilver from '../assets/images/awards/liqeuers/bartender/bartender-silver.png'
import bartenderBronze from '../assets/images/awards/liqeuers/bartender/bartender-bronze.png'

import iscSilver from '../assets/images/awards/liqeuers/isc/isc-silver.svg'
import iscBronze from '../assets/images/awards/liqeuers/isc/isc-bronze.svg'

import sbMaster from '../assets/images/awards/liqeuers/sb/sb-master.svg'
import sbGold from '../assets/images/awards/liqeuers/sb/sb-gold.svg'
import sbSilver from '../assets/images/awards/liqeuers/sb/sb-silver.svg'
import sbBronze from '../assets/images/awards/liqeuers/sb/sb-bronze.png'

import cwsaGold from '../assets/images/awards/liqeuers/cwsa/cwsa-gold.png'
import cwsaSilver from '../assets/images/awards/liqeuers/cwsa/cwsa-silver.png'
import cwsaBronze from '../assets/images/awards/liqeuers/cwsa/cwsa-bronze.png'

import bestOfClass from '../assets/images/awards/liqeuers/BOC/best-of-class.png'


import product from '../assets/images/categoryimages/product.webp'


import brixCommon from '../assets/images/categoryimages/brixcommon/images/brixcommon.webp'
import brixCommonSingle from '../assets/images/pinwheelimages/commonsyrups.png'
import brixCommonLogo from '../assets/images/categoryimages/brixcommon/logos/brixCommonLogo.svg'

import brixPremium from '../assets/images/categoryimages/brixpremium/images/brixpremium.webp'
import brixPremiumSingle from '../assets/images/pinwheelimages/premiumsyrups.png'
import brixPremiumLogo from '../assets/images/categoryimages/brixpremium/logos/brixPremium.svg'


import bitters from '../assets/images/categoryimages/bitters/images/bitters.webp'
import bittersSingle from '../assets/images/pinwheelimages/bitters.png'
import bittersLogo from '../assets/images/categoryimages/bitters/logos/bitters-logo.webp'


import bitterCarousalImage_1 from '../assets/images/categoryimages/bitters/images/carousalimages/bitters1.webp'
import bitterCarousalImage_2 from '../assets/images/categoryimages/bitters/images/carousalimages/bitters2.webp'
import bitterCarousalImage_3 from '../assets/images/categoryimages/bitters/images/carousalimages/bitters3.webp'


import aroma from '../assets/images/categoryimages/aromafinish/images/aroma.webp'
import aromaSingle from '../assets/images/pinwheelimages/aromafinish.png'
import aromaFinishLogo from '../assets/images/categoryimages/aromafinish/logos/aromafinish-logo.svg'
import aromaCarousal1 from '../assets/images/categoryimages/aromafinish/images/carousalimages/aroma-landing-01.jpg'
import aromaCarousal2 from '../assets/images/categoryimages/aromafinish/images/carousalimages/aroma-landing-02.jpg'
import aromaCarousal3 from '../assets/images/categoryimages/aromafinish/images/carousalimages/aroma-landing-03.jpg'


import supertarts from '../assets/images/categoryimages/supertarts/images/supertarts.webp'
import supertartsSingle from '../assets/images/pinwheelimages/supertarts.png'
import subertartLogo from '../assets/images/categoryimages/supertarts/supertarts-logo-black.png'


import tonicwater from '../assets/images/categoryimages/tonicwater/images/tonicwater.webp'
import tonicWaterSingle from '../assets/images/pinwheelimages/toniconcentrates.png'
import tonicWaterLogo from '../assets/images/categoryimages/tonicwater/tonicwater-logo-black.png'

import cocktailPremixSingle from '../assets/images/categoryimages/cocktailpremix/cocktailpremix.png'



import liqeuersSingle from '../assets/images/pinwheelimages/liqueurs.png'

import italianRedLiqLogo from '../assets/images/categoryimages/liquors/italianredbitter/logos/italianRedLiqueurLogo.svg'
import italianRedBitter from '../assets/images/categoryimages/liquors/italianredbitter/images/italianRedLiqueuer.webp'
import italianRedBitterSingle from '../assets/images/categoryimages/liquors/italianredbitter/images/italianredSingle.png'
import italianRedImg1 from '../assets/images/categoryimages/liquors/italianredbitter/images/carousalimages/italianLiqImg1.png'
import italianRedImg2 from '../assets/images/categoryimages/liquors/italianredbitter/images/carousalimages/italianLiqImg2.png'
import italianRedFlavourWheel from '../assets/images/categoryimages/liquors/italianredbitter/images/italianRedFlavourWheel.svg'

import amaraLogo from '../assets/images/categoryimages/liquors/amaroitaliano/logos/amaraLogo.svg'
import amaraItaliano from '../assets/images/categoryimages/liquors/amaroitaliano/images/amaroItaliano.webp'
import amaraItalianoSingle from '../assets/images/categoryimages/liquors/amaroitaliano/images/amaroSingle.png'
import amaraItalianoFlavourWheel from '../assets/images/categoryimages/liquors/amaroitaliano/images/amaroItalianoFlavourWheel.svg'
import amaraItalianoImg1 from '../assets/images/categoryimages/liquors/amaroitaliano/images/carousalimages/amaraItanlianoImg1.webp'
import amaraItalianoImg2 from '../assets/images/categoryimages/liquors/amaroitaliano/images/carousalimages/amaraItanlianoImg2.webp'

import amarettoLogo from '../assets/images/categoryimages/liquors/amaretto/logos/amarettoLogo.svg'
import amaretto from '../assets/images/categoryimages/liquors/amaretto/images/amaretto.webp'
import amarettoSingle from '../assets/images/categoryimages/liquors/amaretto/images/amarettoSingle.png'
import amarettoFlavourWheel from '../assets/images/categoryimages/liquors/amaretto/images/amarettoFlovourWheel.svg'
import amarettoImg1 from '../assets/images/categoryimages/liquors/amaretto/images/carousalimages/amroItalianoImg1.webp'
import amarettoImg2 from '../assets/images/categoryimages/liquors/amaretto/images/carousalimages/amroItalianoImg2.webp'

import repasadoLogo from '../assets/images/categoryimages/liquors/repasado/logos/reposado.svg'
import resapado from '../assets/images/categoryimages/liquors/repasado/images/repasado.webp'
import resapadoSingle from '../assets/images/categoryimages/liquors/repasado/images/reposadoSingle.png'
import resapadoFlavourWheel from '../assets/images/categoryimages/liquors/repasado/images/repasadoFlavourWheel.svg'
import resapadoImg1 from '../assets/images/categoryimages/liquors/repasado/images/carousalimages/repasadoImg1.webp'
import resapadoImg2 from '../assets/images/categoryimages/liquors/repasado/images/carousalimages/repasadoImg2.webp'

import negroniLogo from '../assets/images/categoryimages/liquors/negroni/logos/negroni.svg'
import negroni from '../assets/images/categoryimages/liquors/negroni/images/negroni.webp'
import negroniSingle from '../assets/images/categoryimages/liquors/negroni/images/negroniSingle.png'
import negroniFlavourWheel from '../assets/images/categoryimages/liquors/negroni/images/negroniFlavourWheel.svg'
import negroniImg1 from '../assets/images/categoryimages/liquors/negroni/images/carousalimages/negroniImg1.webp'
import negroniImg2 from '../assets/images/categoryimages/liquors/negroni/images/carousalimages/negroniImg2.webp'
import negroniVideo from '../assets/videos/negroniVideo.webm'

/*Aroma Finish Products */
import productPlaceholderImage from '../assets/images/placeholder_product.png'
import absinthe from '../assets/images/categoryimages/aromafinish/images/absinthe.png'
import agedbourbon from '../assets/images/categoryimages/aromafinish/images/agedbourbon.png'
import cherryblossom from '../assets/images/categoryimages/aromafinish/images/cherryblossom.png'
import indianhighball from '../assets/images/categoryimages/aromafinish/images/indianhighball.png'
import japanesehighball from '../assets/images/categoryimages/aromafinish/images/japanesehighball.png'
import juniper from '../assets/images/categoryimages/aromafinish/images/juniper.png'
import lemonzest from '../assets/images/categoryimages/aromafinish/images/lemonzest.png'
import oakwood from '../assets/images/categoryimages/aromafinish/images/oakwood.png'
import peataroma from '../assets/images/categoryimages/aromafinish/images/peataroma.png'
import tiramisu from '../assets/images/categoryimages/aromafinish/images/tiramisu.png'
import valenciaorange from '../assets/images/categoryimages/aromafinish/images/valenciaorange.png'
import af_yuzu from '../assets/images/categoryimages/aromafinish/images/yuzu.png'

/* brix2 premium prodcuts */
import applecinammon from '../assets/images/categoryimages/brixpremium/images/applecinammon.png'
import benaraspaan from '../assets/images/categoryimages/brixpremium/images/benaraspaan.png'
import blue from '../assets/images/categoryimages/brixpremium/images/blue.png'
import cherrynut from '../assets/images/categoryimages/brixpremium/images/cherrynut.png'
import chocolatebutterscotch from '../assets/images/categoryimages/brixpremium/images/chocolatebutterscotch.png'
import coconut from '../assets/images/categoryimages/brixpremium/images/coconut.png'
import ichibancherryblossom from '../assets/images/categoryimages/brixpremium/images/ichibancherryblossom.png'
import ichiban_japanesematcha from '../assets/images/categoryimages/brixpremium/images/ichiban_japanesematcha.png'
import japanese_greenmelon from '../assets/images/categoryimages/brixpremium/images/japanese_greenmelon.png'
import maplecaramel from '../assets/images/categoryimages/brixpremium/images/maplecaramel.png'
import mixedcitrus from '../assets/images/categoryimages/brixpremium/images/mixedcitrus.png'
import mixedherb from '../assets/images/categoryimages/brixpremium/images/mixedherb.png'
import morocconmint from '../assets/images/categoryimages/brixpremium/images/morocconmint.png'
import passionfruit_honey from '../assets/images/categoryimages/brixpremium/images/passionfruit_honey.png'
import salted_smokeylychee from '../assets/images/categoryimages/brixpremium/images/salted_smokeylychee.png'
import smokey_maple from '../assets/images/categoryimages/brixpremium/images/smokey_maple.png'
import spicy_jamaicancaramel from '../assets/images/categoryimages/brixpremium/images/spicy_jamaicancaramel.png'
import superberries from '../assets/images/categoryimages/brixpremium/images/superberries.png'
import tropicalfruits from '../assets/images/categoryimages/brixpremium/images/tropicalfruits.png'
import turkishdelight from '../assets/images/categoryimages/brixpremium/images/turkishdelight.png'
import vanillabourbon from '../assets/images/categoryimages/brixpremium/images/vanillabourbon.png'
import violet from '../assets/images/categoryimages/brixpremium/images/violet.png'


/* brix2 common products */
import aniseed from '../assets/images/categoryimages/brixcommon/images/aniseed.png'
import basil from '../assets/images/categoryimages/brixcommon/images/basil.png'
import blackcurrant from '../assets/images/categoryimages/brixcommon/images/blackcurrant.png'
import butterscotch from '../assets/images/categoryimages/brixcommon/images/butterscotch.png'
import caramel from '../assets/images/categoryimages/brixcommon/images/caramel.png'
import cardamom from '../assets/images/categoryimages/brixcommon/images/cardamom.png'
import b2c_cherry from '../assets/images/categoryimages/brixcommon/images/cherry.png'
import cinnamon from '../assets/images/categoryimages/brixcommon/images/cinnamon.png'
import b2c_cucumber from '../assets/images/categoryimages/brixcommon/images/cucumber.png'
import b2c_elderflower from '../assets/images/categoryimages/brixcommon/images/elderflower.png'
import greenapple from '../assets/images/categoryimages/brixcommon/images/greenapple.png'
import grenadine from '../assets/images/categoryimages/brixcommon/images/grenadine.png'
import hazelnut from '../assets/images/categoryimages/brixcommon/images/hazelnut.png'
import kiwi from '../assets/images/categoryimages/brixcommon/images/kiwi.png'
import lavendar from '../assets/images/categoryimages/brixcommon/images/lavendar.png'
import malt from '../assets/images/categoryimages/brixcommon/images/malt.png'
import melon from '../assets/images/categoryimages/brixcommon/images/melon.png'
import mojitomint from '../assets/images/categoryimages/brixcommon/images/mojitomint.png'
import musk from '../assets/images/categoryimages/brixcommon/images/musk.png'
import orange from '../assets/images/categoryimages/brixcommon/images/orange.png'
import passionfruit from '../assets/images/categoryimages/brixcommon/images/passionfruit.png'
import peach from '../assets/images/categoryimages/brixcommon/images/peach.png'
import persianrose from '../assets/images/categoryimages/brixcommon/images/persianrose.png'
import pineapple from '../assets/images/categoryimages/brixcommon/images/pineapple.png'
import pomegranate from '../assets/images/categoryimages/brixcommon/images/pomegranate.png'
import pomomolasses from '../assets/images/categoryimages/brixcommon/images/pomomolasses.png'
import rose from '../assets/images/categoryimages/brixcommon/images/rose.png'
import rosemary from '../assets/images/categoryimages/brixcommon/images/rosemary.png'
import strawberry from '../assets/images/categoryimages/brixcommon/images/strawberry.png'
import tangerine from '../assets/images/categoryimages/brixcommon/images/tangerine.png'
import thaijasmine from '../assets/images/categoryimages/brixcommon/images/thaijasmine.png'
import thyme from '../assets/images/categoryimages/brixcommon/images/thyme.png'
import triplesec from '../assets/images/categoryimages/brixcommon/images/triplesec.png'
import vanilla from '../assets/images/categoryimages/brixcommon/images/vanilla.png'
import vetiver from '../assets/images/categoryimages/brixcommon/images/vetiver.png'
import watermelon from '../assets/images/categoryimages/brixcommon/images/watermelon.png'

/* Supertarts products */
import apricot from '../assets/images/categoryimages/supertarts/images/apricot.png'
import banana from '../assets/images/categoryimages/supertarts/images/banana.png'
import supertarts_bergamot from '../assets/images/categoryimages/supertarts/images/bergamot.png'
import bloodorange from '../assets/images/categoryimages/supertarts/images/bloodorange.png'
import calamansi from '../assets/images/categoryimages/supertarts/images/calamansi.png'
import supertarts_coconut from '../assets/images/categoryimages/supertarts/images/coconut.png'
import freshlemon from '../assets/images/categoryimages/supertarts/images/freshlemon.png'
import ginger from '../assets/images/categoryimages/supertarts/images/ginger.png'
import supertarts_grapefruit from '../assets/images/categoryimages/supertarts/images/grapefruit.png'
import supertarts_greenapple from '../assets/images/categoryimages/supertarts/images/greenapple.png'
import kaffirlime from '../assets/images/categoryimages/supertarts/images/kaffirlime.png'
import supertarts_kiwi from '../assets/images/categoryimages/supertarts/images/kiwi.png'
import lemon from '../assets/images/categoryimages/supertarts/images/lemon.png'
import lime from '../assets/images/categoryimages/supertarts/images/lime.png'
import mandarin from '../assets/images/categoryimages/supertarts/images/mandarin.png'
import moroconrose from '../assets/images/categoryimages/supertarts/images/moroconrose.png'
import supertarts_orange from '../assets/images/categoryimages/supertarts/images/orange.png'
import supertarts_pineapple from '../assets/images/categoryimages/supertarts/images/pineapple.png'
import plum from '../assets/images/categoryimages/supertarts/images/plum.png'
import spearmint from '../assets/images/categoryimages/supertarts/images/spearmint.png'
import supertarts_yuzu from '../assets/images/categoryimages/supertarts/images/yuzu.png'

/* Bitters Products*/
import aromatic_XI from '../assets/images/categoryimages/bitters/images/aromatic_XI.jpg'
import aromatic_XII from '../assets/images/categoryimages/bitters/images/aromatic_XII.jpg'
import aromatic_XIII from '../assets/images/categoryimages/bitters/images/aromatic_XIII.jpg'
import celery from '../assets/images/categoryimages/bitters/images/celery.jpg'
import cherry from '../assets/images/categoryimages/bitters/images/cherry.jpg'
import chrysthemum from '../assets/images/categoryimages/bitters/images/chrysthemum.jpg'
import fennel from '../assets/images/categoryimages/bitters/images/fennel.jpg'
import japanese_matcha from '../assets/images/categoryimages/bitters/images/japanese_matcha.jpg'
import lotus from '../assets/images/categoryimages/bitters/images/lotus.jpg'
import marrakesh from '../assets/images/categoryimages/bitters/images/marrakesh.jpg'
import mole from '../assets/images/categoryimages/bitters/images/mole.jpg'
import sandalwood from '../assets/images/categoryimages/bitters/images/sandalwood.jpg'
import thai from '../assets/images/categoryimages/bitters/images/thai.jpg'
import tonka_beans from '../assets/images/categoryimages/bitters/images/tonka_beans.jpg'
import tropical from '../assets/images/categoryimages/bitters/images/tropical.jpg'
import bitters_yuzu from '../assets/images/categoryimages/bitters/images/yuzu.jpg'

/* Tonic Water Products*/
import basiltonic from '../assets/images/categoryimages/tonicwater/images/basiltonic.png'
import bergamot from '../assets/images/categoryimages/tonicwater/images/bergamot.png'
import bluepea from '../assets/images/categoryimages/tonicwater/images/bluepea.png'
import cheeryblossom from '../assets/images/categoryimages/tonicwater/images/cherryblossom.png'
import cucumber from '../assets/images/categoryimages/tonicwater/images/cucumber.png'
import elderflower from '../assets/images/categoryimages/tonicwater/images/elderflower.png'
import frangipani from '../assets/images/categoryimages/tonicwater/images/frangipani.png'
import grapefruit from '../assets/images/categoryimages/tonicwater/images/grapefruit.png'
import indian from '../assets/images/categoryimages/tonicwater/images/indian.png'
import lavender from '../assets/images/categoryimages/tonicwater/images/lavender.png'
import tonic_yuzu from '../assets/images/categoryimages/tonicwater/images/yuzu.png'

// Cocktail Premix
import pinkpaloma from '../assets/images/categoryimages/cocktailpremix/images/pinkpaloma.jpg'
import clearcubalibre from '../assets/images/categoryimages/cocktailpremix/images/clearcubalibre.jpg'
import clearcola from '../assets/images/categoryimages/cocktailpremix/images/clearcola.jpg'


export const categories = [
    {
        id: 7,
        slug: 'cocktail-premix',
        title: 'Cocktail Premix',
        categoryLogo: '',
        categoryImage: cocktailPremixSingle,
        descImg: '',
        aboutCategory: [
            `Indulge in bar-quality and awards winning Cocktails Premix, just mix your favourite spirit and enjoy the occasion. Fresh, Clean, Crisp, Clear and overall, truly refreshing.`,
        ],
        iconData: null,
        products: [
            {
                id: 'cocktail-1',
                slug: 'pink-paloma-cocktail-premix',
                title: 'Pink Paloma Premix',
                productImage: pinkpaloma,
                categoryLogo: '',
                productDescription: [
                    `Summer ripened bitter sweet symphony of Pink Grapefruit, a true South American favourite. Vacation is a state of mind and our Pink Paloma is its state capital.`,
                ],
            },
            {
                id: 'cocktail-2',
                slug: 'cuba-libre-cocktail-premix',
                title: 'Cuba Libre Premix',
                productImage: clearcubalibre,
                categoryLogo: '',
                productDescription: [
                    `A classic highball cocktail with few additional twists. This ageless classic in its new avatar is clear, crisp, and refreshing. Best mixed with clear spirits.`,
                ],
            },
            {
                id: 'cocktail-3',
                slug: 'clear-cola-concentrate',
                title: 'Clear Cola Concentrate',
                productImage: clearcola,
                categoryLogo: '',
                productDescription: [
                    `The crystal Clear Cola Concentrate packs all the elements of cola and being clear. It's your secret weapon for crafting innovative clear cola cocktails - be it clear Long Island Iced Tea or Cuba Libre.`,
                ],
            },
        ],
        awards: [
            {
                id: 'award-cat-1',
                title: 'International Wine & Spirit Competition (IWSC) 2021',
                awardDetails: [
                    {
                        id: 'award-1',
                        image: iwscBronze,
                        title: 'Pink Paloma Cocktail Premix',
                    },
                    {
                        id: 'award-2',
                        image: iwscBronze,
                        title: 'Cuba Libre With Yuzu Premix',
                    },
                ],
            },
        ],
        productCarousalImages: [pinkpaloma, clearcubalibre, clearcola],
    },
    {
        id: 0,
        slug: "tonic-water-concentrates",
        title: 'Tonic Concentrates',
        categoryLogo: tonicWaterLogo,
        categoryImage: tonicWaterSingle,
        descImg: tonicwater,
        aboutCategory: [
            `Presenting Sakurafresh Tonic Quarter, a tonic water concentrate that makes making homemade tonic water simple. Our bittering agent is the gentian root revered in Ayurveda for increasing immunity and health. Developed in 2018 by Sakurafresh Japan, this concentrate has a more affordable price per drink than bottled options while still providing a fresh, artisanal flavor. In contrast to ordinary tonic water, you can create a unique cocktail by varying the concentration levels in your Gin & Tonic.`,
            `Usage: Mix one part of the Tonic Water Concentrate with six to eight parts of carbonated (soda) water, stir gently until fully dissolved and serve chill - your tonic water is ready with freshness. Seven parts dilution give you a fine balance between sweetness and bitterness in your tonic water.`,
        ],
        iconData: [
            {
                icon: '',
                text: '1 part tonic water concentrate',
            },
            {
                icon: '',
                text: '6 parts soda/sparkling water',
            },
            {
                icon: '',
                text: 'stir gently',
            },
        ],
        products: [
            {
                id: 'tw-11',
                slug: 'Yuzu',
                title: 'Yuzu',
                productImage: tonic_yuzu,
                categoryLogo: tonicWaterLogo,
                productDescription: [
                    `Our Yuzu Tonic Water Concentrate is bright - vibrant and citrus. Japanese Yuzu citrus has a central role with a long, floral and sweet after finish.`,
                ],
            },
            {
                id: 'tw-10',
                slug: 'Lavender',
                title: 'Lavender',
                productImage: lavender,
                categoryLogo: tonicWaterLogo,
                productDescription: [
                    `The bold, crispy aroma of summer harvested lavender flowers pairs well with any beverage, when made from Lavender Tonic Water concentrate.`
                ],
            },
            {
                id: 'tw-9',
                slug: 'Indian',
                title: 'Indian',
                productImage: indian,
                categoryLogo: tonicWaterLogo,
                productDescription: [
                    `Our Indian Tonic Water Concentrate delivers a rich, earthy and classic mild bitter flavour, helps accentuating the notes of the paired alcohol.`
                ],
            },
            {
                id: 'tw-8',
                slug: 'Grapefruit',
                title: 'Grapefruit',
                productImage: grapefruit,
                categoryLogo: tonicWaterLogo,
                productDescription: [
                    `Our Grapefruit Tonic Water Concentrate delivers rich, earthy and bittersweet flavours. Pink grapefruit zest with herbal tones that blend well with the bitterness of Gentian root.`
                ],
            },
            {
                id: 'tw-7',
                slug: 'Frangipani',
                title: 'Frangipani',
                productImage: frangipani,
                categoryLogo: tonicWaterLogo,
                productDescription: [
                    `Frangipani never disappoints with its delicate, ethereal aroma, capturing hints of apricots and citrus notes among the clear floral scent.`,
                ],
            },
            {
                id: 'tw-6',
                slug: 'Elderflower',
                title: 'Elderflower',
                productImage: elderflower,
                categoryLogo: tonicWaterLogo,
                productDescription: [
                    `Our Elderflower Tonic Water Concentrate captures the subtle flowery-fruity notes flavours of the night-harvested Elderflower, blends well with the bitterness of Gentian root.`
                ],
            },
            {
                id: 'tw-5',
                slug: 'Cucumber',
                title: 'Cucumber',
                productImage: cucumber,
                categoryLogo: tonicWaterLogo,
                productDescription: [
                    `Our Cucumber Tonic Water Concentrate is cooling, crisp and delicate. We retained the aroma of freshly sliced cucumbers, complemented with the bitterness of Gentian root.`
                ],
            },
            {
                id: 'tw-4',
                slug: 'Cherry-Blossom',
                title: 'Cherry Blossom',
                productImage: cheeryblossom,
                categoryLogo: tonicWaterLogo,
                productDescription: [
                    `Our Cherry Blossom Tonic Water Concentrate brings forward the fruity - sweet - floral notes of Sakura flowers which blend well with the bitterness of Gentian root. A favourite for Rokugin fans in their G&T.`
                ],
            },
            {
                id: 'tw-3',
                slug: 'Blue-Pea',
                title: 'Blue Pea',
                productImage: bluepea,
                categoryLogo: tonicWaterLogo,
                productDescription: [
                    `Our Blue Pea Tonic Water Concentrate has fresh - earthy - woody notes from Violet flowers and Thai butterfly blue, pea flowers, merged with the bitterness of Gentian root.`
                ],
            },
            {
                id: 'tw-2',
                slug: 'Bergamot',
                title: 'Bergamot',
                productImage: bergamot,
                categoryLogo: tonicWaterLogo,
                productDescription: [
                    `Our Bergamot Tonic Water Concentrate delivers a distinctive aromatic flavour of Italian Bergamot with subtle back notes of grapefruit and fennel. `
                ],
            },
            {
                id: 'tw-1',
                slug: 'basil',
                title: 'Basil',
                productImage: basiltonic,
                categoryLogo: tonicWaterLogo,
                productDescription: [
                    `Flavour notes of green - fresh - vegetal linger right till the finish in the tonic water made from our Basil Tonic Water Concentrate.`
                ],
            },
        ],
    },
    {
        id: 2,
        slug: "super-tarts",
        title: 'Supertarts',
        categoryLogo: subertartLogo,
        categoryImage: supertartsSingle,
        descImg: supertarts,
        aboutCategory: [
            `Supertarts, is our brand of citrus based hand crafted, small batch sweet & sour syrup using the best quality natural ingredients which are blended and allowed to mature - slowly. This extended integration allows the individual ingredients to marry and merge with time - so the harsh notes and sharp edges of individual ingredients get softened and integrate well together, resulting in superior smoothness and flawless flavour of Tarts.`,
            `Usage: Stir one part Tart with four to five parts of chilled carbonated (Soda) water with ice in your favourite cocktail recipe. Our Tarts can also be used in mocktails, iced teas, pastries, cakes and desserts.`,
        ],
        iconData: [
            {
                icon: '',
                text: '1 part tart',
            },
            {
                icon: '',
                text: '5 parts soda/sparkling water (chilled)',
            },
        ],
        products: [
            {
                id: 'spr-21',
                productImage: supertarts_yuzu,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Our Japanese Yuzu tart brings citric sharpness - sweet and heady - floral fragrance, addig its own unique profile to each drink.`,
                ],
                title: 'Yuzu',
                slug: 'Yuzu',
            },
            {
                id: 'spr-20',
                productImage: spearmint,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Fresh green spearmint, cooling, candy, leafy, spicy with a warm wintergreen afternote, adding its own unique profile to any drink.`
                ],
                title: 'Spearmint',
                slug: 'Spearmint',
            },
            {
                id: 'spr-19',
                productImage: supertarts_pineapple,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Our Pineapple Tart has aroma of fruity, sweet, ripe pineapple with distinct fresh summer notes and a lingering after taste, adding its own unique profile to any drink.`,
                ],
                title: 'Pineapple',
                slug: 'Pineapple',
            },
            {
                id: 'spr-18',
                productImage: plum,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Sweet and sour, mature, juicy and deeply floral. Plums can bring in the finishing tart that makes a drink complete. Hints of strawberry, cherries complete the flavour palette, adding its own unique profile to any drink.`,
                ],
                title: 'Plum',
                slug: 'Plum',
            },
            {
                id: 'spr-17',
                productImage: supertarts_orange,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Natural fresh sweet pressed, season's best Valencia oranges with distinct notes of summer ripened fresh citrus, adding its own unique profile to any drink.`,
                ],
                title: 'Orange',
                slug: 'Orange',
            },
            {
                id: 'spr-16',
                productImage: moroconrose,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Summer harvested Moroccan Desert rose has a distinct sweet linger well rounded taste with a complex garden-fresh aroma, adding its own unique profile to any drink.`,
                ],
                title: 'Moroccon Rose',
                slug: 'Moroccon-Rose',
            },
            {
                id: 'spr-15',
                productImage: mandarin,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Sweet and Tangy fresh notes of summer harvested Mandarins with a full-bodied citrus aroma, adding its own unique profile to any drink.`
                ],
                title: 'Mandarin',
                slug: 'Mandarin',
            },
            {
                id: 'spr-14',
                productImage: lime,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Sharp plant ripened acid limes with distinct lime flavour brimming with characteristic full-bodied lingering lime and distinct citrus notes, adding its own unique profile to any drink.`
                ],
                title: 'Lime',
                slug: 'Lime',
            },
            {
                id: 'spr-13',
                productImage: lemon,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Sharp plant ripened Amalfi coast lemons with distinct summer - coast line flavour with a full bodied - picked at peak fresh aroma. Fresh Sorrento lemons dominate the nose with afternote of fresh sea breeze, adds its own profile to any drink.`,
                ],
                title: 'Lemon',
                slug: 'Lemon',
            },
            {
                id: 'spr-12',
                productImage: supertarts_kiwi,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Distinctive notes of Golden Kiwi which are tropical than Green
                    Kiwi. The distinctive golden yellow flesh is less tart with a more pronounced tropical flavour than green kiwifruit. Hint of mango and tropical berrires, adding its own profile to any drink.`,
                ],
                title: 'Kiwi',
                slug: 'Kiwi',
            },
            {
                id: 'spr-11',
                productImage: kaffirlime,
                categoryLogo: subertartLogo,
                productDescription: [
                    `The leaves and rind of Kaffir Lime have an aroma unlike any other citrus, sometimes mysterious and alluring. There is a combined lemon, lime and mandarin fruity aroma adding its own unique profile to any drink.`,
                ],
                title: 'Kaffir Lime',
                slug: 'Kaffir-Lime',
            },
            {
                id: 'spr-10',
                productImage: supertarts_greenapple,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Juicy, sweet, crisp and crunchy - Just what an apple tastes like. Green Apples give deep depth to tarts and are very complimentary to each other as flavours, adding its own unique profile to any drink.`
                ],
                title: 'Green Apple',
                slug: 'Green-Apple',
            },
            {
                id: 'spr-9',
                productImage: supertarts_grapefruit,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Fresh, tarty and tropical- with a mild bitter note and a firm citrus flavour - reminiscent of a vine plucked Pomelo. Mild bitterness meets the fresh complex citrus note. Works great with chilled sparkling water.`,
                ],
                title: 'Grapefruit',
                slug: 'Grapefruit',
            },
            {
                id: 'spr-8',
                productImage: ginger,
                categoryLogo: subertartLogo,
                productDescription: [
                    `A unique blend of three of the world's finest naturally sourced gingers, subtle botanical flavours and spring water. Perfectly balanced to enhance the spirits notes. Works great with sparkling water to give a well rounded ginger Ale note.`,
                ],
                title: 'Ginger',
                slug: 'Ginger',
            },
            {
                id: 'spr-7',
                productImage: freshlemon,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Lemon zest provides a deep lemony flavour - blend of floral and citrus notes and well-rounded smooth finish. Fresh notes accentuate the overall experience and adds its own unique flavour profile to any drink.`,
                ],
                title: 'Fresh Lemon Zest',
                slug: 'Fresh-Lime-Zest',
            },
            {
                id: 'spr-6',
                productImage: supertarts_coconut,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Fresh fruity, coconutty milky, sweet buttery, green and wood meets the tart from the vinegar resulting in tropical flavour burst. Our Coconut Tart adds its own unique flavour profile to any drink.`,
                ],
                title: 'Coconut',
                slug: 'Coconut',
            },
            {
                id: 'spr-5',
                productImage: calamansi,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Calamansi is a hybrid between the mandarin orange and the oval kumquat - Calamansi Tart provides a straight citrus note with a lingering sweet afternote. It is bold, tropical and full bodied - a citrus memory.`,
                ],
                title: 'Calamansi',
                slug: 'Calamansi',
            },
            {
                id: 'spr-4',
                productImage: bloodorange,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Our Blood Orange tart has stronger and intense aroma than a normal orange. Has a distinct sweet, bitter flavour, adding its own unique flavour profile to drink.`,
                ],
                title: 'Blood Orange',
                slug: 'Blood-Orange',
            },
            {
                id: 'spr-3',
                productImage: supertarts_bergamot,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Bergamot is often described as the soul of Calabria, Italy and Earl Grey Tea. The primary aromas of citrus splits into two distinct; citrus with citrus peel and citrus with coriander seed. Secondary aromas are woody pine and floral summer rose.`,
                ],
                title: 'Bergamot',
                slug: 'Bergamot',
            },
            {
                id: 'spr-2',
                productImage: banana,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Banana Tart has a pulpy, ripe, sweet, creamy and full of flavour. Mild after notes of cream and vanilla, adding its own unique flavour profile to any drink.`
                ],
                title: 'Banana',
                slug: 'Banana',
            },
            {
                id: 'spr-1',
                productImage: apricot,
                categoryLogo: subertartLogo,
                productDescription: [
                    `Apricot Tart have unique mild musky with a faint tartness that lies somewhere between a peach and a plum. Distinct floral nuances highlight its unique identity. It has green - vegetal - fresh notes adding unique flavour profile to any drink.`,
                ],
                title: 'Apricot',
                slug: 'Apricot',
            },
        ],
    },
    {
        id: 1,
        slug: "bitters",
        title: 'Bitters',
        categoryLogo: bittersLogo,
        categoryImage: bittersSingle,
        descImg: bitters,
        aboutCategory: [
            `Better than Bitters is world's first Zero Alcoholic Bitters which creates a world of possibility working with cocktails, teas, infused drinks, cooking, and baking recipes. Each of our bitters are blended in very small batches to bring the freshness and aromatic flavours with infusions. Designed and formulated in Japan.`,
            `Usage: one to two drops in your favourite cocktails or 100 ml of water/ soda. Use chilled water/soda with ice for best results. Do NOT consume directly without diluting.`,
        ],
        iconData: [
            {
                icon: '',
                text: '2 drops of bitters',
            },
            {
                icon: '',
                text: '100 ml soda/sparkling water (chilled)',
            },
        ],
        products: [
            {
                id: 'btr-16',
                slug: 'Yuzu-Citrus',
                title: 'Yuzu Citrus',
                productImage: bitters_yuzu,
                video: 'https://image.sakurafresh.com/bitters/SF-bitters-yuzucitrus(final).mp4',
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Citric sharpness - Sweet and heady - floral fragrance. Characteristic yuzu note, meets citrus notes of mandarins & Sorrento Lemons. Overall a distinct Japanese experience.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `Vodka, Gin or Tequila.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Martini, Margarita, Gin & Tonic. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `Our Yuzu Citrus Bitter has a tart and fragrant flavour with overtones of mandarin orange. It has a very distinct aroma, this invigorating wave of fresh Japanese Yuzu aroma coupled with a refreshing citrus taste that spreads through your palette.`,
                ],
            },
            {
                id: 'btr-15',
                slug: 'Tropical',
                title: 'Tropical',
                productImage: tropical,
                video: 'https://image.sakurafresh.com/bitters/SF-bitters-tropical(final).mp4',
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Tropical - warm - earthy - fruity - sweetness - Vegetal. Tropical fruits such as Banana, Mango, Litchi and coconut dominate the nose and a mild hint of tropical piña coladas suggests a warm tropical - beach experience.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `Rum, Vodka or Gin.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Mai Tai, Daiquiri, Gin Sour, Vodka Tonic or Piña Colada. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `Even if you can't always be relaxing on a beach with a cocktail in hand, you can bring a taste of the tropical islands. A mix of sweet mango, banana, fig, grape, pomegranate and lychee, this bitters is designed to add a tropical lift to any cocktail. Shake the bottle well before use.`,
                ],
            },
            {
                id: 'btr-14',
                slug: 'Tonka-Beans',
                title: 'Tonka Beans',
                productImage: tonka_beans,
                video: null,
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Aroma of vanilla, cherry, almond, and something spicy - a bit like cinnamon. When served cold - the taste is like a vanilla caramel with dark honey.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `Whisky, Rum, Tequila or Vodka.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Espresso Martini, Old Fashioned, Negroni or any Tiki Cocktails with Rum. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `Tonka beans have been a culinary favourite for chefs for years now, a drop or two into a favourite beverage alleviates your experience. Add it to your desired Tiki drinks and switch on your party lights.`,
                ],
            },
            {
                id: 'btr-13',
                slug: 'Thai',
                title: 'Thai',
                productImage: thai,
                video: 'https://image.sakurafresh.com/bitters/SF-bitters-thai(final).mp4',
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Vegetal - Herby - Heat - Fruity. Clear notes of Galangal and Lemongrass make the overall feel aromatic and very Thai. Faint hints of Passionfruit, coconut and fresh caramel give a well-rounded palette.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `Vodka and Gin.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Gin & Tonic, Gin Sour, Martini or any Thai Style Cocktails. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `Our bitters is influenced by Simplicity Thai cuisine, based on meshing spicy, sweet, sour and salty into every dish. And those big, bold flavours transfer excellently into big, bold cocktail recipes. A great mix of enticing flavours, citrusy Thai flavours like galangal, lemongrass, dill and passion fruit.`,
                ],
            },
            {
                id: 'btr-12',
                slug: 'Sandalwood',
                title: 'Sandalwood',
                productImage: sandalwood,
                video: null,
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Vegetal - Warm - Fresh - Inherent creamy sweetness - Woody - Piney. This bitter covers a wide aromatic spectrum ranging from clear woody to faint Vanilla. Hints of fresh caramel enunciate the overall aroma and flavour.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `Whisky, Vodka and Gin.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Old Fashioned, Martini or Sours. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `Our Sandalwood Bitters are infused with rare and luxurious Sandalwood barks and chips from Mysore in South India with a woody note with lighter more fragrant vanilla. The aroma of our Bitters is smooth with divine notes covering both ends of the flavour spectrum.`,
                ],
            },
            {
                id: 'btr-11',
                slug: 'Mole',
                title: 'Mole',
                productImage: mole,
                video: 'https://image.sakurafresh.com/bitters/SF-bitters-mole(final).mp4',
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Chocolate - Heat - Vegetal - Hint of sweet. Distinct notes of warm coffee, Cappuccino and freshly brewed coffee dominate the nose. Dark Chocolate and its characteristic creamy cocoa bean taste with hint of Mexican chilli follow through with the flavour as dominant notes.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `Whisky, Rum, Vodka, Tequila.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Espresso Martini, Negroni, Rum Old Fashioned; Cocktails made with Tequila, Mezcal, or Dark Rum. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `If you love the taste of smoky, chocolatey - spicy chilly Mexican mole sauce; infusing Better than Bitters - Mole Bitters brings a unique and exotic touch to your cocktails. Our Mole Bitters are perfect addition to cocktails made with tequila, mezcal, or dark rum to bring a little chocolatey, smoky flavour with only a mere hint of heat.`,
                ],
            },
            {
                id: 'btr-10',
                slug: 'Marrakesh',
                title: 'Marrakesh',
                productImage: marrakesh,
                video: 'https://image.sakurafresh.com/bitters/SF-bitters-marrakesh-01(final).mp4',
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Bold spice - strong nose and pronounced fresh spice notes - such as Coriander, Cumin, Cinnamon and Cloves. This Bitter also carries a faint hint of Moroccan Desert Roses and Liquorice. Warm - Floral - Spice dominate the nose.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `All Spirits such as Whisky, Gin, Vodka, Mezcal, Tequilas and Japanese Sake & Sochu.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Whisky Sour, Old Fashioned, Margarita, Gin & Tonic; Tequilas, Mezcals and Vermouths based Cocktails. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `A dash of our Marrakesh bitters takes your imagination on a quick ride to the bustling and exotic spice souks of Marrakech city in Morocco. Be transported to another world when you blend our Marrakesh Bitters with rum, whisky, white spirits, tequilas, mezcals or vermouth - based cocktails.`,
                ],
            },
            {
                id: 'btr-9',
                slug: 'Lotus',
                title: 'Lotus',
                productImage: lotus,
                video: null,
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Floral, pleasant, heady and sweet.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `Gin, Vodka or White Rum.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Martini, Daiquiri, Gin & Tonic, Sours. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `Subtle, delicate, and divinely aromatic - the floral notes of the lotus bitters will take to a place that is serene, peaceful, and very calm.`,
                ],
            },
            {
                id: 'btr-8',
                slug: 'Japanese-Matcha',
                title: 'Japanese Matcha',
                productImage: japanese_matcha,
                video: 'https://image.sakurafresh.com/other/SF-bitters-japaneseMatcha(final).mp4',
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Grassy creaminess, with notes of white flowers, and umami. Floral elements from citronella give a fresh burst of flavour. Minor notes of mist, vegetal and honey and maple give a boost to the flavour of highland grown Matcha.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `Whisky, Vodka and Japanese Sake & Sochu.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Highball, Sake Martinis or Vodka Sour. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `Our Matcha Bitters made from Japanese Premium Matcha grown in the highlands of Shizuoka prefecture in beautiful Japan. It highlights complex umami flavours, an earthy element and a retro hue to cocktails.`,
                ],
            },
            {
                id: 'btr-7',
                slug: 'Fennel',
                title: 'Fennel',
                productImage: fennel,
                video: null,
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Herby - anise - vegetal - earthy - well rounded fresh nose. Prominent fennel notes with hints of fresh berry, warm spices and faint citrus - Bergamot note.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `Gin, White Rum, Vodka and Whisky.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Gin & Tonic, Fennel Martini, Saketini. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `Fennel's strong anise quality can be an acquired taste, it has a rich, liquorice flavour, but it's refreshing rather than bold. When used in cocktails, its cooling and calming on a hot day - will lend the distinctive flavour of fennel seeds and zing to you drinks.`,
                ],
            },
            {
                id: 'btr-6',
                slug: 'Chrysanthemum',
                title: 'Chrysanthemum',
                productImage: chrysthemum,
                video: null,
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Floral, dominant, earthy and herby - aroma brings autumn memories.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `Vodka, Gin, Tequila or White Rum.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Martini, Daiquiri, Gin & Tonic or Rickey. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `The smell of these wonderful flowers is earthy and herby - rather than the sweet aromas typically associated with bouquets. In Japan, the chrysanthemum is deeply revered and respected.`,
                ],
            },
            {
                id: 'btr-5',
                slug: 'Cherry',
                title: 'Cherry',
                productImage: cherry,
                video: 'https://image.sakurafresh.com/bitters/SF-bitters-cherry(final).mp4',
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Light fruity and floral - slightly candy like smell to it with a hint of bitters or acridness to it rounding out and providing a grounding or weighting of the cherry aroma so it has a better nose.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `Gin, White Rum, Vodka and Whisky.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Cherry Gin Fizz, Cherry Manhattan, Cherry Vodka Sour, Cuban Libre or Maine Cocktails. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `If you love a good Manhattan like we really do - a drop or two of Sakurafresh's Cherry Bitters adds complexity and balance to your cocktail with distinct cherry taste with a pleasantly sweet - sour - bitterness. Try out in Cuba Libre, Manhattan, Martini, Old fashioned or the good old school Maine cocktails.`,
                ],
            },
            {
                id: 'btr-4',
                slug: 'Celery',
                title: 'Celery',
                productImage: celery,
                video: 'https://image.sakurafresh.com/bitters/SF-bitters-celery(final).mp4',
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Distinct crisp and vegetal celery notes with distinct fresh notes of coriander, star anise, thai basil add to the well-rounded finish.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `Tequila, Vodka, White Rum.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Bloody Mary, Margarita, Daiquiri, Gin & Tequila Cocktails. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `Celery Bitters are a long-forgotten cocktail ingredient that add a nice savoury boost to gin, tequila, Bloody Mary and Martinis. It offers a well-rounded and a full bouquet of notes such as white sweet grape, high land Shizuoka Sencha tea, lemon grass, celery leaf and fresh root ginger. It artistically balances on the palette as well, adding coriander seed, fresh celery and chicory to the melange of experiences.`,
                ],
            },
            {
                id: 'btr-3',
                slug: 'Aromatic-XIII',
                title: 'Aromatic XIII',
                productImage: aromatic_XIII,
                video: null,
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Cherry with hints of licorice, Mint - Anise - Clove Gentian - Cinnamon - Vegetal.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `All Spirits such as Whisky, Gin, Vodka, Mezcal, Tequilas and Japanese Sake & Sochu.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Sazerac, Pink Gin, Mint Julep, Cognac or Rye-based cocktails. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `Our Aromatic XIII Bitters have a fruity herby, sweet juiciness of ripe red cherries with a distinct liquorice root and spearmint. You also experience hints of warm Christmas spices and anise in the background. Made from blend of various herbs and spices extracts, it has a dark cherry colour and distinct fruity-spicy aroma. As an aromatic bitter, the base bitter flavour is from gentian root extract. Pairs well for Sazerac, Cognac or Rye-based cocktail drinks in particular.`,
                ],
            },
            {
                id: 'btr-2',
                slug: 'Aromatic-XII',
                title: 'Aromatic XII',
                productImage: aromatic_XII,
                video: null,
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Citrus - Fresh oranges - Clove - Gentian - Cinnamon - Mixed Bitter - Strongly aromatic.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `All Spirits such as Whisky, Gin, Vodka, Mezcal, Tequilas and Japanese Sake & Sochu.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Old Fashioned, Manhattan or Negroni. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `Our Aromatic XII Bitters have a distinct citrusy ripe orange, herbal and spicy flavours with hints of clove and cinnamon. Made from blend of orange, various herbs and spicesc extracts, it has a dark reddish-brown colour and distinct citrusy aroma. As an aromatic bitter, the base bitter flavour is from gentian root extract. Aromatic XII Bitters taste great with vodka, gin, & whisky.`,
                ],
            },
            {
                id: 'btr-1',
                slug: 'Aromatic-XI',
                title: 'Aromatic XI',
                productImage: aromatic_XI,
                video: null,
                categoryLogo: bittersLogo,
                aboutProduct: [
                    {
                        title: 'Tasting Notes',
                        content: `Clove - Gentian - Cinnamon - Mixed Bitter  Strongly aromatic.`,
                    },
                    {
                        title: 'Spirits Pairing',
                        content: `All Spirits such as Whisky, Gin, Vodka, Mezcal, Tequilas and Japanese Sake & Sochu.`,
                    },
                    {
                        title: 'Cocktail Recommendation',
                        content: `Old Fashioned, Whisky Sour or Highball. These are some of the recommended cocktails, Bitters can be paired with any cocktails of your choice.`,
                    },
                ],
                productDescription: [
                    `Our Aromatic XI Bitters have a hearty blend of herbs and spices with hints of clove and cinnamon. Various barks, berries, herbs and spices are macerated imparting a distinct dark reddish-brown character. As an aromatic bitter, the base bitter flavour is from gentian root extract. Aromatic XI Bitters taste great with aged spirits: whiskies, rums, and brandies.`,
                ],
            },
        ],
        awards: [
            {
                id: 'award-cat-1',
                title: 'International Wine & Spirit Competition (IWSC) 2021',
                awardDetails: [
                    {
                        id: 'award-1',
                        image: iwscSilver,
                        title: 'Mole Bitters',
                    },
                    {
                        id: 'award-2',
                        image: iwscSilver,
                        title: 'Marrakesh',
                    },
                    {
                        id: 'award-3',
                        image: iwscBronze,
                        title: 'Aromatic XI',
                    },
                ],
            },
            {
                id: 'award-cat-2',
                title: 'London Spirits Competition Awards 2022',
                awardDetails: [
                    {
                        id: 'award-1',
                        image: lscSilver,
                        title: 'Aromatic XI',
                    },
                    {
                        id: 'award-2',
                        image: lscSilver,
                        title: 'Aromatic XIII',
                    },
                    {
                        id: 'award-3',
                        image: lscSilver,
                        title: 'Japanese Matcha',
                    },
                    {
                        id: 'award-4',
                        image: lscSilver,
                        title: 'Marrakesh',
                    },
                    {
                        id: 'award-5',
                        image: lscSilver,
                        title: 'Mole Bitters',
                    },
                ],
            },
            {
                id: 'award-cat-3',
                title: 'Bartender Spirits Awards 2022',
                awardDetails: [
                    {
                        id: 'award-1',
                        image: bartenderSilver,
                        title: 'Aromatic XI',
                    },
                    {
                        id: 'award-2',
                        image: bartenderSilver,
                        title: 'Chrysanthemum',
                    },
                    {
                        id: 'award-3',
                        image: bartenderSilver,
                        title: 'Japanese Matcha',
                    },
                    {
                        id: 'award-4',
                        image: bartenderSilver,
                        title: 'Lotus Bitters',
                    },
                    {
                        id: 'award-5',
                        image: bartenderSilver,
                        title: 'Aromatic XI',
                    },
                    {
                        id: 'award-6',
                        image: bartenderSilver,
                        title: 'Chrysanthemum',
                    },
                    {
                        id: 'award-7',
                        image: bartenderSilver,
                        title: 'Japanese Matcha',
                    },
                    {
                        id: 'award-8',
                        image: bartenderSilver,
                        title: 'Lotus Bitters',
                    },
                ],
            },
            {
                id: 'award-cat-4',
                title: 'International Spirits Challenge Awards 2022',
                awardDetails: [
                    {
                        id: 'award-1',
                        image: iscBronze,
                        title: 'Marrakesh',
                    },
                    {
                        id: 'award-2',
                        image: iscBronze,
                        title: 'Yuzu Citrus',
                    },
                ],
            },
            {
                id: 'award-cat-5',
                title: 'Global Spirits Masters Medals 2021',
                awardDetails: [
                    {
                        id: 'award-1',
                        image: sbSilver,
                        title: 'Lotus Bitters',
                    },
                    {
                        id: 'award-2',
                        image: sbSilver,
                        title: 'Mole Bitters',
                    },
                ],
            }
        ],
        productCarousalImages: [bitterCarousalImage_1, bitterCarousalImage_2, bitterCarousalImage_3],
    },
    {
        id: 3,
        slug: "zero-alcohol-spirits",
        title: 'Zero Alcohol Spirits',
        categoryLogo: '',
        categoryImage: liqeuersSingle,
        descImg: '',
        aboutCategory: [
            ``,
        ],
        iconData: [
            {
                icon: '',
                text: '',
            },
        ],
        products: [
            {
                id: 'liq-05',
                slug: "negroni",
                categoryName: 'Cocktails',
                title: `Negroni`,
                subTitle: 'Classic Cocktail (Zero Alcohol)',
                productImage: negroni,
                pinwheelImage: negroniSingle,
                productDescription: [
                    ``
                ],
                aboutProduct: [
                    {
                        id: 0,
                        title: 'Classic Cocktail (Zero Alcohol)',
                        content: `Sakurafresh's Negroni, a Zero Alcohol Classic Cocktail. With dominant notes of sweet orange, spices, juniper, ripe berries and a short spicy - bittersweet finish. This stunning scarlet of a cocktail is a party starter. Negroni is best served on ice with an orange wedge or stirred with your choice of spirit to make your favourite variant of this classic cocktail.`,
                    },
                    {
                        id: 1,
                        title: 'Tasting Notes',
                        content: [
                            `AROMA : Honey, Dried fruits, Juniper, Berries, Citrus, Orange, Spices, Cream, Vanilla, Stone fruits;`,
                            `PALATE : Citrus, Spices, Bitterness, Honey sweetness, Plum cake;`,
                            `MOUTHFEEL : Well-rounded creamy palate, lingering bitterness, clove and cinnamon spice, honey.`,
                        ],
                    },
                    {
                        id: 2,
                        title: 'Cocktails Recommendation',
                        content: `On the rocks for Zero Alcohol Classic Negroni. Add 10 ml of Gin to make the popular Alcohol Classic Negroni. You can also try out Negroni variants such as Negroni Sour, Jamaican Negroni, Boulevardier, Peach Negroni, Mezcal Negroni and Coffee Negroni to name a few.`,
                    },
                ],
                productAwards: [
                    {
                        id: 'award-1',
                        title: 'Winner of Gold Medal',
                        awardImage: ssGold,
                        competition: 'Concours Mondial de Bruxelles 2021',
                        awardHonor: 'the highest spirits award worldwide',
                    },
                    {
                        id: 'award-2',
                        title: 'Winner of Gold Medal',
                        awardImage: cwsaGold,
                        competition: 'China Wines & Spirits (CWSA) Awards 2021',
                        awardHonor: '',
                    },
                    {
                        id: 'award-3',
                        title: 'Winner of Silver Medal',
                        awardImage: iscSilver,
                        competition: `International Spirits Challenge 2022`,
                        awardHonor: 'a major global spirits competition',
                    },
                    {
                        id: 'award-4',
                        title: 'Winner of Bronze Medal',
                        awardImage: lscBronze,
                        competition: `Lodnon Spirits Competition 2022`,
                        awardHonor: 'a major global spirits competition',
                    },
                    {
                        id: 'award-5',
                        title: 'Winner of Bronze Medal',
                        awardImage: bartenderBronze,
                        competition: 'Bartender Spirits Award 2022',
                        awardHonor: 'ta major global spirits competition',
                    },
                ],
                flavourWheel: negroniFlavourWheel,
                productCarousalImages: [negroniImg1, negroniImg2],
                video: 'https://image.sakurafresh.com/liqueurs/SF-negroni(final).mp4',
            },
            {
                id: 'liq-04',
                slug: "reposado",
                categoryName: 'Spirits',
                title: `Reposado`,
                subTitle: 'Zero Alcohol Mezcal Spirit',
                productImage: resapado,
                pinwheelImage: resapadoSingle,
                productDescription: [
                    ``
                ],
                aboutProduct: [
                    {
                        id: 0,
                        title: 'Zero Alcohol Mezcal Spirit',
                        content: `Sakurafresh's Reposado, with it's distinct flavours of agave, honey, white pepper and caramel with a lingering spicy and smoky finish, is a bright and lively spirit which combines earthy, spicy and fruity notes giving you a party on your palate.`,
                    },
                    {
                        id: 1,
                        title: 'Tasting Notes',
                        content: `Scents of caramel, agave, honey and white pepper. Spicy and sweet at the same time, touches of earthy agave, hints of vanilla and honey caramel sweetness. Nice long linger, full of pepper and spice, lingering sweetness of honey & caramel. A smoky mesquite memory that stays for a long time.`,
                    },
                    {
                        id: 2,
                        title: 'Cocktails Recommendation',
                        content: `In cocktails such as Margarita, Mezcal Mule, Paloma, Mezcal Sour & Mezcal Old Fashioned or just sip from shot glass. Our Reposado is a direct substitute for any Tequila or Mezcal cocktails recipes, versatile for Alcohol and Zero Alcohol cocktails.`,
                    },
                ],
                productAwards: [
                    {
                        id: 'award-1',
                        title: 'Winner of Gold Medal',
                        awardImage: cwsaGold,
                        competition: 'China Wines & Spirits (CWSA) Awards 2021',
                        awardHonor: '',
                    },
                    {
                        id: 'award-2',
                        title: 'Winner of Sliver Medal',
                        awardImage: lscSilver,
                        competition: 'London Spirits Competition 2022',
                        awardHonor: 'a major global spirits competition',
                    },
                    {
                        id: 'award-3',
                        title: 'Winner of Silver Medal',
                        awardImage: bartenderSilver,
                        competition: `Bartender Spirits Awards 2022`,
                        awardHonor: 'a major global spirits competition',
                    },
                    {
                        id: 'award-4',
                        title: 'Winner of Bronze Medal',
                        awardImage: iwscBronze,
                        competition: `International Wine & Spirit Competition (IWSC) 2021`,
                        awardHonor: 'a major global spirits competition',
                    },
                ],
                flavourWheel: resapadoFlavourWheel,
                productCarousalImages: [resapadoImg1, resapadoImg2],
                video: 'https://image.sakurafresh.com/liqueurs/SF-reposado(final).mp4',
            },
            {
                id: 'liq-01',
                slug: "italian-red-bitter",
                categoryName: 'Liqueurs',
                title: `Italian Red Bitter`,
                subTitle: 'Zero Alcohol Amaro Liqueur',
                productImage: italianRedBitter,
                pinwheelImage: italianRedBitterSingle,
                productDescription: [
                    ``
                ],
                aboutProduct: [
                    {
                        id: 0,
                        title: 'Zero Alcohol Amaro Liqueur',
                        content: `Sakurafresh's complex, bright and lively Italian Red Bitter Liqueur, with it's dominant notes of bittersweet orange, spices, sweet and juicy cherry, and a short bittersweet afternote on the finish will always leave you wanting for more.`,
                    },
                    {
                        id: 1,
                        title: 'Tasting Notes',
                        content: `Strong bittersweet orange. It is very complex and there are notes of cherry, clove, and cinnamon as well. It is one of the bitter amaro liqueur you will savour, further adding to its appeal.`,
                    },
                    {
                        id: 2,
                        title: 'Cocktails Recommendation',
                        content: `In cocktails such as Negroni, Jungle Bird, Italian Old Fashioned, Garibaldi, Italian Spritz or just straight pour on rocks. Italian Red Bitter liqueur is an amaro and can be a direct substitute for any Campari or Aperol based cocktails. Versatile amaro liqueur for Alcohol and Zero Alcohol cocktails.`,
                    },
                ],
                productAwards: [
                    {
                        id: 'award-1',
                        title: `Winner of 'Best Of Class'`,
                        awardImage: bestOfClass,
                        competition: 'San Francisco World Spirits Competition 2022',
                        awardHonor: 'the highest spirits award worldwide',
                    },
                    {
                        id: 'award-2',
                        title: 'Winner of Double Gold Medal',
                        awardImage: wscGold,
                        competition: 'San Francisco World Spirits Competition 2022',
                        awardHonor: 'the highest spirits award worldwide',
                    },
                    {
                        id: 'award-3',
                        title: 'Winner of Gold Medal',
                        awardImage: bartenderGold,
                        competition: 'Bartender Spirits Awards 2022',
                        awardHonor: 'a mojor global spirits competition',
                    },
                    {
                        id: 'award-4',
                        title: 'Winner of Silver Medal',
                        awardImage: sbSilver,
                        competition: `'The Spirits Masters' Competition 2021`,
                        awardHonor: 'a major global spirits blind competition',
                    },
                    {
                        id: 'award-5',
                        title: 'Winner of Silver Medal',
                        awardImage: cwsaSilver,
                        competition: 'China Wines & Spirits (CWSA) Awards 2021',
                        awardHonor: 'chinese highest spirits competition',
                    },
                    {
                        id: 'award-6',
                        title: 'Winner of Silver Medal',
                        awardImage: iscSilver,
                        competition: 'International Spirits Challenge 2022',
                        awardHonor: 'a major global spirits competition.',
                    },
                    {
                        id: 'award-7',
                        title: 'Winner of Silver Medal',
                        awardImage: lscSilver,
                        competition: 'London Spirits Competition 2022',
                        awardHonor: 'a major global spirits competition',
                    },
                    {
                        id: 'award-8',
                        title: 'Winner of Bronze Medal',
                        awardImage: iwscBronze,
                        competition: 'International Wine & Spirit Competition, 2021',
                        awardHonor: 'the top global spirits competition',
                    },
                ],
                flavourWheel: italianRedFlavourWheel,
                productCarousalImages: [italianRedImg1, italianRedImg2],
                video: 'https://image.sakurafresh.com/liqueurs/SF-italianredbitter(final).mp4',
            },
            {
                id: 'liq-02',
                slug: "amaro-italiano",
                categoryName: 'Liqueurs',
                title: `Amaro Italiano`,
                subTitle: 'Zero Alcohol Amaro Liqueur',
                productImage: amaraItaliano,
                pinwheelImage: amaraItalianoSingle,
                productDescription: [
                    ``
                ],
                aboutProduct: [
                    {
                        id: 0,
                        title: 'Zero Alcohol Amaro Liqueur',
                        content: `Sakurafresh's Amaro Italiano Liqueur, a first of its kind globally, is a delicate combination of mild herbal notes with hints of citrus fills the air. This is promptly followed by dominant notes and sweetness of caramel and hints of espresso to bring about a bitter finish.`,
                    },
                    {
                        id: 1,
                        title: 'Tasting Notes',
                        content: `Herbal bittersweet. Smooth on the tongue with mild herbal and a hint of citrus. Promptly followed by dominant caramel flavours, a hint of espresso and a sweet, lightly bitter finish.`,
                    },
                    {
                        id: 2,
                        title: 'Cocktails Recommendation',
                        content: `In cocktails such as Black Manhattan, Amaro Highball, Amaro Cola, Sicilian Estate or just straight pour on rocks. Amaro Italiano liqueur is an amaro and can be a direct substitute for any Averna or Jägermeister based cocktails. Versatile amaro liqueur for Alcohol and Zero Alcohol cocktails.`,
                    },
                ],
                productAwards: [
                    {
                        id: 'award-1',
                        title: 'Winner of Gold Medal',
                        awardImage: wscSingleGold,
                        competition: 'San Francisco World Spirits Competition 2022',
                        awardHonor: 'the highest spirits award worldwide',
                    },
                    {
                        id: 'award-2',
                        title: 'Winner of Gold Medal',
                        awardImage: sbGold,
                        competition: `'The Spirits Masters' competition 2021`,
                        awardHonor: `a major global spirits blind competition`,
                    },
                    {
                        id: 'award-3',
                        title: 'Winner of Silver Medal',
                        awardImage: lscSilver,
                        competition: `London Spirits Competition 2022`,
                        awardHonor: 'a major global spirits competition',
                    },
                    {
                        id: 'award-4',
                        title: 'Winner of Bronze Medal',
                        awardImage: iscBronze,
                        competition: `International Spirits Challenge 2022`,
                        awardHonor: 'a major global spirits competition',
                    },
                    {
                        id: 'award-5',
                        title: 'Winner of Bronze Medal',
                        awardImage: iwscSilver,
                        competition: 'International Wine & Spirit Competition (IWSC) 2022',
                        awardHonor: 'the top global spirits competition',
                    },
                    {
                        id: 'award-6',
                        title: 'Winner of Silver Medal',
                        awardImage: bartenderSilver,
                        competition: 'Bartender Spirits Award 2022',
                        awardHonor: 'ta major global spirits competition',
                    },
                    {
                        id: 'award-7',
                        title: 'Winner of Bronze Medal',
                        awardImage: cwsaBronze,
                        competition: `in China Wines & Spirits (CWSA) Awards 2021`,
                        awardHonor: 'the highest spirits award worldwide',
                    },
                ],
                flavourWheel: amaraItalianoFlavourWheel,
                productCarousalImages: [amaraItalianoImg1, amaraItalianoImg2],
                video: 'https://image.sakurafresh.com/liqueurs/SF-amaroitaliano(final).mp4',
            },
            {
                id: 'liq-03',
                slug: "amaretto",
                categoryName: 'Liqueurs',
                title: `Amaretto`,
                subTitle: 'Zero Alcohol Liqueur',
                productImage: amaretto,
                pinwheelImage: amarettoSingle,
                productDescription: [
                    ``
                ],
                aboutProduct: [
                    {
                        id: 0,
                        title: 'Zero Alcohol Liqueur',
                        content: `Sakurafresh's Amaretto Liqueur with dominant flavours of sweet and nutty almonds with a lingering toasty afternote on the finish, is a bright and lively liqueur which practically jumps out of the glass.`,
                    },
                    {
                        id: 1,
                        title: 'Tasting Notes',
                        content: `The almond flavour is sweet, nutty and toasted back note on the finish.`,
                    },
                    {
                        id: 2,
                        title: 'Cocktails Recommendation',
                        content: `In cocktails such as Godfather, Amaretto Sour, Amaretto Martini, Pina Amore, Amaretto Cola or just straight pour on rocks.Amaretto liqueur can be used in any Amaretto based cocktails. Versatile liqueur for Alcohol and Zero Alcohol cocktails.`,
                    },
                ],
                productAwards: [
                    {
                        id: 'award-1',
                        title: 'Winner of Double Gold Medal',
                        awardImage: wscGold,
                        competition: 'San Francisco World Spirits Competition 2022',
                    },
                    {
                        id: 'award-2',
                        title: 'Winner of Double Gold Medal',
                        awardImage: ssGold,
                        competition: 'Concours Mondial de Bruxelles 2021',
                    },
                    {
                        id: 'award-3',
                        title: 'Winner of Master Medal',
                        awardImage: sbMaster,
                        competition: `'The Spirits Masters' Competition 2021`,
                    },
                    {
                        id: 'award-4',
                        title: 'Winner of Bronze Medal',
                        awardImage: lscBronze,
                        competition: `London Spirits Competition 2022`,
                    },
                    {
                        id: 'award-5',
                        title: 'Winner of Silver Medal',
                        awardImage: iscSilver,
                        competition: 'International Spirits Challenge 2022',
                    },
                    {
                        id: 'award-6',
                        title: 'Winner of Silver Medal',
                        awardImage: bartenderSilver,
                        competition: 'Bartender Spirits Award 2022',
                    },
                    {
                        id: 'award-7',
                        title: 'Winner of Bronze Medal',
                        awardImage: cwsaBronze,
                        competition: `China Wines & Spirits (CWSA) Awards 2021`,
                    },
                ],
                flavourWheel: amarettoFlavourWheel,
                productCarousalImages: [amarettoImg1, amarettoImg2],
                video: 'https://image.sakurafresh.com/liqueurs/SF-amaretto(final).mp4',
            },
        ],
    },
    {
        id: 4,
        slug: "common-syrups",
        title: 'Common Syrups',
        categoryLogo: brixCommonLogo,
        categoryImage: brixCommonSingle,
        descImg: brixCommon,
        aboutCategory: [
            `brix² is our range of premium and common cocktail syrups. Two simple rules guide us - our commitment to top notch ingredients and adding value to your beverage experience. We believe that life's best moments are spent through time with friends and family, enjoying each other's company and sharing a drink. The importance of that experience should be reflected in the quality of the drinks you serve.`,
            `USAGE: Stirring one part syrup with four to five parts of chilled carbonated (soda) water in your favourite cocktail recipe.`,
        ],
        iconData: [
            {
                icon: '',
                text: '1 part brix² common syrup',
            },
            {
                icon: '',
                text: '5 parts soda/ sparkling water (chilled)',
            },
        ],
        products: [
            {
                id: 'brix-c-36',
                slug: 'Watermelon',
                title: 'Watermelon',
                productImage: watermelon,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Our highly aromatic Watermelon Syrup adds excitement and flavour to beverages and dishes. A great addition with cocktails, mocktails, sodas, iced teas, lemonades and desserts.`
                ],
            },
            {
                id: 'brix-c-35',
                slug: 'Vetiver-Khus',
                title: 'Vetiver (Khus)',
                productImage: vetiver,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Our Vetiver Syrup allows you to craft the unique woody taste of Vetiver plant root to add a touch of elegant ruggedness to every beverage and dish. The perfect addition to cocktails, iced teas, yogurts, milkshakes, or drizzled over desserts.`
                ],
            },
            {
                id: 'brix-c-34',
                slug: 'Vanilla',
                title: 'Vanilla',
                productImage: vanilla,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `One of the most memorable, full-bodied spices to produce numerous sweet and savoury flavours. A classic addition to cocktails, mocktails, sodas, ice teas, lemonades, lattes, or drizzled over fruit and desserts.`
                ],
            },
            {
                id: 'brix-c-33',
                slug: 'Triple-Sec',
                title: 'Triple Sec',
                productImage: triplesec,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Our Triple Sec Syrup stays true to the liqueur's character of dried peels of sweet and bitter oranges, while being 100% Zero Alcohol. The perfect addition to cocktails, sodas, iced teas, lemonades, or drizzled over fruit and desserts.`
                ],
            },
            {
                id: 'brix-c-32',
                slug: 'Thyme',
                title: 'Thyme',
                productImage: thyme,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Feel like a true chef as you joke about how “Thyme flies” in the kitchen. Our Thyme Syrup allows for creative experimentation in every beverage. A unique addition to cocktails, mocktails, lemonades or desserts.`
                ],
            },
            {
                id: 'brix-c-31',
                slug: 'Thai-Jasmine',
                title: 'Thai Jasmine',
                productImage: thaijasmine,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Our Thai Jasmine Syrup has the aromatic fragrances of the Jasmine flowers to enhance every dish and beverage. A fragrant addition to cocktails, iced teas, rice, baked goods, or drizzled over fruit and desserts.`
                ],
            },
            {
                id: 'brix-c-30',
                slug: 'Tangerine',
                title: 'Tangerine',
                productImage: tangerine,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Our Tangerine Syrup serves as the Orange's cooler, older cousin, serving as an ingredient with a real kick. The perfect addition to cocktails, mocktails, sodas, iced teas, lemonades, cakes, baked goods, ice cream, or desserts.`
                ],
            },
            {
                id: 'brix-c-29',
                slug: 'Strawberry',
                title: 'Strawberry',
                productImage: strawberry,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `The flavour of this berry has been adding character to countless pastries, desserts, and beverages. Try our take on this evergreen fruit with our fresh and aromatic Strawberry Syrup. The perfect addition to cocktails, sodas and desserts.`
                ],
            },
            {
                id: 'brix-c-28',
                slug: 'Rosemary',
                title: 'Rosemary',
                productImage: rosemary,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `An incomparable herb in every culinary kitchen, our Rosemary Syrup adds a flavourful, balanced dash of both sweet and spicy. A great addition to cocktails, mocktails, sodas, iced teas, lemonades, or drizzled over fruit and desserts.`
                ],
            },
            {
                id: 'brix-c-27',
                slug: 'Rose',
                title: 'Rose',
                productImage: rose,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `If you're looking for a romantic cocktail, look no further! Our Rose Syrup will add a vivid floral scent and flavour that is balanced—not aggressive. A lovely addition to cocktails, sodas, iced teas, lemonades and drizzled over fruit or desserts.`
                ],
            },
            {
                id: 'brix-c-26',
                slug: 'Pomegranate',
                title: 'Pomegranate',
                productImage: pomegranate,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `With an aromatic flavour and memorable texture, Pomegranate is one fruit that you won’t forget. Our Pomegranate Syrup brings you adventure in a bottle. The perfect addition to cocktails, sodas, iced teas, lemonades, or drizzled desserts.`,
                ],
            },
            {
                id: 'brix-c-25',
                slug: 'Pomegranate-Molasses',
                title: 'Pomegranate Molasses',
                productImage: pomomolasses,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `The party version of the pomegranate - Pomegranate Molasses is a thickly flavoured syrup with a natural and subtle sweetness. The perfect addition to cocktails, mocktails, sodas, iced teas, lemonades, or drizzled over desserts.`,
                ],
            },
            {
                id: 'brix-c-24',
                slug: 'Pineapple',
                title: 'Pineapple',
                productImage: pineapple,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Our Pineapple Syrup with its tangy flavours of freshly cut pineapple immediately transports you to the warmer months. No wonder it's a classic ingredient in cocktails such as the Piña Colada! An refreshing addition to cocktails and sodas.`
                ],
            },
            {
                id: 'brix-c-23',
                slug: 'Persian-Rosé',
                title: 'Persian Rosé',
                productImage: persianrose,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Our Persian Rosé Syrup is the perfect ingredient to bring you to the intricate Persian gardens: providing a distinctive and yet delicate flavour. The perfect addition to cocktails, mocktails, sodas, iced teas, lemonades, or drizzled over fruit.`
                ],
            },
            {
                id: 'brix-c-22',
                slug: 'Peach',
                title: 'Peach',
                productImage: peach,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `The sweet nectar of peaches is enjoyed across a range of beverages and desserts, our Peach Syrup can help craft endless creative mixes without overpowering. A great addition to cocktails, sodas, iced teas, lemonades, or drizzled over desserts.`
                ],
            },
            {
                id: 'brix-c-21',
                slug: 'Passion-Fruit',
                title: 'Passion Fruit',
                productImage: passionfruit,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Passion Fruit's sweet, tarty flavour is renowned. Our Passion Fruit Syrup brings you this aromatic and international fruit in a bottle. The perfect addition to cocktails, mocktails, soda, ice teas, lemonades, or drizzled over desserts.`
                ],
            },
            {
                id: 'brix-c-20',
                slug: 'Orange',
                title: 'Orange',
                productImage: orange,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Our Orange Syrup brings a tangy and distinct orange flavour to you, ready to be enjoyed in traditional and unconventional ways. A great addition to cocktails, mocktails, sodas, iced teas, lemonades or baked goods.`
                ],
            },
            {
                id: 'brix-c-19',
                slug: 'Musk',
                title: 'Musk',
                productImage: musk,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Our Musk Syrup serves as an exotic ingredient in all your concoctions with its sweet and fleshy mouthfeel. The perfect addition to cocktails, mocktails, sodas, iced teas, lemonades, or drizzled over desserts. `,
                ],
            },
            {
                id: 'brix-c-18',
                slug: 'Mojito-Mint',
                title: 'Mojito Mint',
                productImage: mojitomint,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Mojitos are one of Cuba's greatest gifts to the world! Our Mojito Mint Syrup with its both minty fresh and slightly sweet flavour, brings a refreshing touch to a classic cocktail. A great addition to cocktails, sodas, iced teas and lemonades.`,
                ],
            },
            {
                id: 'brix-c-17',
                slug: 'Melon',
                title: 'Melon',
                productImage: melon,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Enjoy the sweet taste of summer, a refreshing and light choice when you want to put yourself in a tropical mood. Great way to add flavour to cocktails, mocktails, sodas, iced teas, lemonades, or drizzled over fruit and desserts.`,
                ],
            },
            {
                id: 'brix-c-16',
                slug: 'Malt',
                title: 'Malt',
                productImage: malt,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `The dewy, brown malt is often recognized as a component of the beer and whisky making process, adding both soul and character to every beverage and dish. An exciting addition to cocktails, cakes, baked goods, iced cream or desserts.`,
                ],
            },
            {
                id: 'brix-c-15',
                slug: 'Lavender',
                title: 'Lavender',
                productImage: lavendar,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Our Lavender Syrup is as aromatic as flavourful. Allow us to transport you to a provincial lavender field, as the sun sets upon the glowing purple landscape. A unique addition to cocktails, sodas, iced teas, baked goods or desserts.`,
                ],
            },
            {
                id: 'brix-c-14',
                slug: 'Kiwi',
                title: 'Kiwi',
                productImage: kiwi,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Adding character with its smooth skin, vivid green colour, and countless seeds, our Kiwi Syrup adds more tropical, tangy taste and sweet aroma. The perfect addition to cocktails, sodas, iced teas, lemonades, or drizzled over desserts.`,
                ],
            },
            {
                id: 'brix-c-13',
                slug: 'Hazelnut',
                title: 'Hazelnut',
                productImage: hazelnut,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `A classic aroma with a subtle nutty flavour. Our carefully crafted Hazelnut Syrup serves as the secret ingredient to revive any drink or dish. The perfect addition to cocktails, mocktails, sodas, ice teas, lemonades, baked goods or cakes.`
                ],
            },
            {
                id: 'brix-c-12',
                slug: 'Grenadine',
                title: 'Grenadine',
                productImage: grenadine,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Traditionally Grenadine was made with pomegranate juice and sweetened with drops of lemon juice and orange - flower water. Now a bar classic ingredient, it gives a vibrant and exciting red hue . A great addition any drinks or desserts.`
                ],
            },
            {
                id: 'brix-c-11',
                slug: 'Green-Apple',
                title: 'Green Apple',
                productImage: greenapple,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Prefer the crisp tart of green apples? Our Green Apple Syrup has a sweet - tangy flavour that adds enthusiasm to any celebration or beverage. A great addition to cocktails, mocktails, sodas, iced teas and lemonades.`,
                ],
            },
            {
                id: 'brix-c-10',
                slug: 'Elderflower',
                title: 'Elderflower',
                productImage: b2c_elderflower,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Our Elderflower Syrup is timeless, allowing you to explore the flower's distinctively musky and sweet flavour, yet subtle and elegant. An elegant addition to cocktails, mocktails, sodas, iced teas, lemonades, or drizzled over fruit and desserts.`
                ],
            },
            {
                id: 'brix-c-9',
                slug: 'Cucumber',
                title: 'Cucumber',
                productImage: b2c_cucumber,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `For a fruit made of majority water, Cucumbers offer a most distinctive aroma and taste. Our Cucumber syrup will serve as both a complimentary and transformative ingredient. A great addition to cocktails, iced teas, lemonades, and sodas.`
                ],
            }, {
                id: 'brix-c-8',
                slug: 'Cinnamon',
                title: 'Cinnamon',
                productImage: cinnamon,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `An intricate blend of sweet and spicy, cinnamon is a flavour
                    enjoyed year round, ideal ingredient to add a memorable dash of character. The best addition to cocktails, mocktails, sodas, iced teas, lemonades, baked goods, fruits and desserts.`
                ],
            },
            {
                id: 'brix-c-7',
                slug: 'Cherry',
                title: 'Cherry',
                productImage: b2c_cherry,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `The natural sweetness of a classic red cherry is instantly recognizable. Our Cherry Syrup is full-bodied and aromatic, ready to be used in a fusion of flavours. The perfect addition to cocktails, mocktails, soda, or drizzled over fruit and desserts.`
                ],
            },
            {
                id: 'brix-c-6',
                slug: 'Cardamom',
                title: 'Cardamom',
                productImage: cardamom,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Our versatile cardamom syrup is the perfect secret ingredient to add a memorable tingle. This gentle spicy is the perfect flavour to add with cocktails, mocktails, soda, iced teas, lemonades, or drizzled over fruit and desserts.`
                ],
            },
            {
                id: 'brix-c-5',
                slug: 'Caramel',
                title: 'Caramel',
                productImage: caramel,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `This syrup is one of the glorious ingredients that goes with pretty much anything. It especially adds a rich, impactful flavour when it comes to cocktails and desserts, enhances cocktails, sodas, iced teas, smoothie, cappuccinos, or ice creams.`
                ],
            },
            {
                id: 'brix-c-4',
                slug: 'Butterscotch',
                title: 'Butterscotch',
                productImage: butterscotch,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Remember those golden wrapped treats from your childhood ? The nostalgic memory of butterscotch is everlasting. A great way to add a kick to the cocktails, soda, ice teas, lemonade, smoothies, cappuccinos or drizzled over ice cream.`
                ],
            },
            {
                id: 'brix-c-3',
                slug: 'Blackcurrant',
                title: 'Blackcurrant',
                productImage: blackcurrant,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `To say blackcurrant is the queen of berries is no exaggeration! A strong, pronounced aroma and taste. Great way to add tangy flavour to cocktails, sodas, iced teas, or as a drizzle over fruit, desserts, cheesecakes, pies or yogurt.`
                ],
            },
            {
                id: 'brix-c-2',
                slug: 'Basil',
                title: 'Basil',
                productImage: basil,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Light and aromatic, our summery Basil Syrup will add a hint of fresh sweet basil to your favourites. The perfect way to add flavour to cocktails, mocktails, sodas, ice teas, lemonades, drizzled over fruit or as a dressing on desserts.`
                ],
            },
            {
                id: 'brix-c-1',
                slug: 'Aniseed',
                title: 'Aniseed',
                productImage: aniseed,
                categoryLogo: brixCommonLogo,
                productDescription: [
                    `Resembling black liquorice in both taste and aroma, Aniseed still has a distinct sweet taste. A perfect addition to cocktails, sodas, iced teas, lemonades, pastries, baked goods, or drizzled over fruit and desserts.`
                ],
            },


        ],
    },
    {
        id: 5,
        slug: "premium-syrups",
        title: 'Premium Syrups',
        categoryLogo: brixPremiumLogo,
        categoryImage: brixPremiumSingle,
        descImg: brixPremium,
        aboutCategory: [
            `brix² is our range of premium and common cocktail syrups. Two simple rules guide us - our commitment to top notch ingredients and adding value to your beverage experience. We believe that life's best moments are spent through time with friends and family, enjoying each other's company and sharing a drink. The importance of that experience should be reflected in the quality of the drinks you serve.`,
            `Usage is stirring one part syrup with four to five parts of chilled carbonated (soda) water in your favourite cocktail recipe.`,
        ],
        iconData: [
            {
                icon: '',
                text: '1 part brix² premium syrup',
            },
            {
                icon: '',
                text: '5 parts soda/sparkling water (chilled)',
            },
        ],
        products: [
            {
                id: 'brix-p-21',
                productImage: vanillabourbon,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `With two classic flavours blended, a perfect flavour balance of full-bodied vanilla complemented by the caramel notes of bourbon. Best suitable for cocktails, mocktails, soda, ice teas, baking and desserts.`
                ],
                title: 'Vanilla Bourbon',
                slug: 'Vanilla Bourbon',
            },
            {
                id: 'brix-p-20',
                productImage: turkishdelight,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `Our rose flavoured Turkish Delight Syrup was inspired by the classic Middle Eastern Sweet. Very floral and fragrant with rosy notes is a great combination for cocktails, mocktails, soda, ice teas, lemonades and even desserts.`
                ],
                title: 'Turkish Delight',
                slug: 'Turkish-Delight',
            },
            {
                id: 'brix-p-19',
                productImage: tropicalfruits,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `Recreate relaxing on a beach with a cocktail in hand, you can bring a taste of the tropical islands. A mix of sweet mango, pineapple, orange, peach and pomegranate, this syrup is designed to add a tropical lift to any cocktail.`
                ],
                title: 'Tropical Fruits',
                slug: 'Tropical-Fruits',
            },
            {
                id: 'brix-p-18',
                productImage: superberries,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `Our Super Berries Syrup is a balanced blend of strawberries, raspberries, blackcurrant and lychee flavours. It takes the sweetness, sharpness and fragrant flavours to add a twist to cocktails, mocktails, soda, ice teas and even desserts.`
                ],
                title: 'Super Berries',
                slug: 'Super-Berries',
            },
            {
                id: 'brix-p-17',
                productImage: spicy_jamaicancaramel,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `Sipping something tropical on a beach in Jamaica, our Jamaican - inspired spicy caramel syrup has a perfect blend of spiciness with sweety caramel after-taste. Great way to add a twist to cocktails, soda, ice teas, lemonades and even desserts.`
                ],
                title: 'Spicy Jamaican Caramel',
                slug: 'Spicy-Jamaican-Caramel',
            },
            {
                id: 'brix-p-16',
                productImage: smokey_maple,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `Our Salted Smoky Maple syrup delivers both sugar and smoke to any drinks. Great way to add a smoky, sweet twist to cocktails, mocktails, soda, ice teas, lemonades and even desserts.`
                ],
                title: 'Smoky Maple',
                slug: 'Smoky-Maple',
            },
            {
                id: 'brix-p-15',
                productImage: salted_smokeylychee,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `An intriguingly delicious unison of lychee aroma balanced with a distinct smokey saltiness. Great way to add flavour to cocktails, mocktails, soda, ice teas, lemonades and even desserts.`
                ],
                title: 'Salted Smokey Lychee',
                slug: 'Salted-Smokey-Lychee',
            },
            {
                id: 'brix-p-14',
                productImage: passionfruit_honey,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `With its intense passion fruit flavour and sweety touch of honey, our syrup is just waiting for some of your imagination. Great way to add flavour to cocktails, mocktails, soda, ice teas, lemonades and even desserts.`
                ],
                title: 'Passion Fruit & Honey',
                slug: 'Passion-Fruit-Honey',
            },
            {
                id: 'brix-p-13',
                productImage: morocconmint,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `Our Moroccan Mint syrup influenced by Marrakesh markets features a full - bodied flavour and aroma that invigorates and freshens to create a minty fresh refreshing lift to cocktails, mocktails, ice teas, pastry glazes, and frozen desserts.`
                ],
                title: 'Moroccan Mint',
                slug: 'Moroccan-Mint',
            },
            {
                id: 'brix-p-12',
                productImage: mixedherb,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `Mixed Herb Syrup is a treat for any season and brings fresh, leafy flavour to cocktails, mocktails, soda, ice teas, lemonades and even desserts.`
                ],
                title: 'Mixed Herb',
                slug: 'Mixed-Herb',
            },
            {
                id: 'brix-p-11',
                productImage: mixedcitrus,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `This refreshingly sweet and citrusy syrup is just the thing to perk up your drink and give it a zesty kick. Great way to add flavour to cocktails, mocktails, soda, ice teas, lemonades and even desserts.`
                ],
                title: 'Mixed Citrus',
                slug: 'Mixed-Citrus',
            },
            {
                id: 'brix-p-10',
                productImage: maplecaramel,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `A smooth and silky feel syrup, it has a rich, maple flavour with a buttery caramely finish. Great way to add flavour to cocktails, mocktails, soda, ice teas, lemonades and even desserts.`
                ],
                title: 'Maple Caramel',
                slug: 'Maple-Caramel',
            },
            {
                id: 'brix-p-9',
                productImage: japanese_greenmelon,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `Feel the Japanese melons aroma on the nose with sweet notes coming through and lingering onto the palate. Our best seller syrup worldwide. Great way to add flavour to cocktails, mocktails, soda, ice teas, lemonades and even desserts.`
                ],
                title: 'Japanese Green Melon',
                slug: 'Japanese-Green-Melon',
            },
            {
                id: 'brix-p-8',
                productImage: ichiban_japanesematcha,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `Our syrup is made from Japanese Premium Matcha grown in the highlands of Shizuoka prefecture in beautiful Japan. It highlights complex umami flavours, an earthy element and a retro hue to cocktails and sodas.`
                ],
                title: 'Japanese Matcha',
                slug: 'Japanese-Matcha',
            },
            {
                id: 'brix-p-7',
                productImage: ichibancherryblossom,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `Our syrup brings forward the fruity - sweet - floral notes of Cherry Blossoms (Sakura) flowers of Japan. Our signature and best seller syrup worldwide. The subtlety of the floral aroma makes it versatile to add to cocktails , mocktails, soda, ice teas and more.`
                ],
                title: 'Cherry Blossoms',
                slug: 'Cherry-Blossoms',
            },
            {
                id: 'brix-p-6',
                productImage: coconut,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `The perfect aroma and taste to evoke images of tropical beaches and white washed waves. Our Coconut Syrup offer a lush and exotic flavour. A great addition to a variety of cocktails, soda, ice teas, lemonades, cakes, fruit, and pastries.`
                ],
                title: 'Coconut',
                slug: 'Coconut',
            },
            {
                id: 'brix-p-5',
                productImage: chocolatebutterscotch,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `The smooth creamy butterscotch taste is enhanced by chocolate to elevate this flavour combination. Great way to add flavour to cocktails, mocktails, soda, ice teas, lemonades and even desserts.`
                ],
                title: 'Choco Butterscotch',
                slug: 'Choco-Butterscotch',
            },
            {
                id: 'brix-p-4',
                productImage: cherrynut,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `The perfect sweet & tarty syrup with cherry nuts aroma. Great way to add flavour to cocktails, mocktails, soda, ice teas, lemonades and even desserts.`
                ],
                title: 'Cherry Nut',
                slug: 'Cherry-Nut',
            },
            {
                id: 'brix-p-3',
                productImage: blue,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `Blue Syrup made using Thai Pea Flowers creates mesmerizing appeal, bit woody and grassy with slight nuttiness taste. The subtlety of the aroma, though, makes it versatile to add to cocktails, mocktails, soda, ice teas and desserts.`
                ],
                title: 'Blue',
                slug: 'Blue',
            },
            {
                id: 'brix-p-2',
                productImage: benaraspaan,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `Influenced from the mesmerising taste of flavourful varieties of Paan sold in Benares in India. It is a medley of multiple flavours from the cardamom, cloves, fennel seeds and predominantly sweet, suitable for cocktails and sodas.`
                ],
                title: 'Benares Paan',
                slug: 'Benares-Paan',
            },
            {
                id: 'brix-p-1',
                productImage: applecinammon,
                categoryLogo: brixPremiumLogo,
                productDescription: [
                    `The tasty delight of fresh picked apples accented with a hint of cinnamon is a flavour combination that lasts a lifetime. It's a pleasant, homey flavour that you want to add to cocktails, soda, ice teas or poured over pancakes, waffles and cheesecakes.`
                ],
                title: 'Apple Cinnamon',
                slug: 'Apple-Cinnamon',
            },
        ],
    },
    {
        id: 6,
        slug: "aroma-finish",
        title: 'Aroma Finish',
        categoryLogo: aromaFinishLogo,
        categoryImage: aromaSingle,
        descImg: aroma,
        aboutCategory: [
            `Discover Sakurafresh Aroma Finish - the ideal way to elevate your cocktails and desserts. Our innovative spray atomizer delivers delightful, edible aromas to your beverages and sweet treats. Simply apply two sprays to the glass rim or dessert dish to introduce a unique flavour dimension.`,
            `Transform your experience and awaken your senses with Sakurafresh Aroma Finish.`,
        ],
        iconData: null,
        products: [
            {
                id: 'aroma-12',
                slug: 'Yuzu',
                title: 'Yuzu',
                productImage: af_yuzu,
                categoryLogo: aromaFinishLogo,
                productDescription: [
                    `A delightful Japanese citrus aroma that resembles a combination of mandarin and grapefruit.`
                ],
            },
            {
                id: 'aroma-11',
                slug: 'Valencia-Orange',
                title: 'Valencia Orange',
                productImage: valenciaorange,
                categoryLogo: aromaFinishLogo,
                productDescription: [
                    `Familiar over ripe Italian orange aroma with a hint of sweetness and green notes of fragrant leaves and stems.`
                ],
            },
            {
                id: 'aroma-10',
                slug: 'Tiramisu',
                title: 'Tiramisu',
                productImage: tiramisu,
                categoryLogo: aromaFinishLogo,
                productDescription: [
                    `A beautiful bakery aroma with clear notes of chocolate, cream, coffee and mascarpone cheese.`
                ],
            },
            {
                id: 'aroma-9',
                slug: 'Peat',
                title: 'Peat',
                productImage: peataroma,
                categoryLogo: aromaFinishLogo,
                productDescription: [
                    `A bewitching, earthy aroma of ancient moss, a must finish for your whisky and cocktails.`
                ],
            },
            {
                id: 'aroma-8',
                slug: 'Oakwood',
                title: 'Oakwood',
                productImage: oakwood,
                categoryLogo: aromaFinishLogo,
                productDescription: [
                    `Rich smoky wood aroma, reminiscent of matured whisky barrels with sweet vanilla undertones.`,
                ],
            },
            {
                id: 'aroma-7',
                slug: 'Lemon-Zest',
                title: 'Lemon Zest',
                productImage: lemonzest,
                categoryLogo: aromaFinishLogo,
                productDescription: [
                    `Lemon Zest provides a deep Sorrento lemons aroma, blend of floral and citrus notes.`
                ],
            },
            {
                id: 'aroma-6',
                slug: 'Juniper-Aroma',
                title: 'Juniper Aroma',
                productImage: juniper,
                categoryLogo: aromaFinishLogo,
                productDescription: [
                    `Our finish gives an aromatic note of fresh juniper berries to your favourite Gin and Tonic.`
                ],
            },
            {
                id: 'aroma-5',
                slug: 'Japanese-Highball',
                title: 'Japanese Highball',
                productImage: japanesehighball,
                categoryLogo: aromaFinishLogo,
                productDescription: [
                    `True aroma notes of Japanese roasted malts - enhances any Highball and whisky cocktails.`
                ],
            },
            {
                id: 'aroma-4',
                slug: 'Indian-Highball',
                title: 'Indian Highball',
                productImage: indianhighball,
                categoryLogo: aromaFinishLogo,
                productDescription: [
                    `True aroma notes of roasted Indian barley with strong notes of malt - enhances any Highball and whisky cocktails.`,
                ],
            },
            {
                id: 'aroma-3',
                slug: 'Cherry-Blossom',
                title: 'Cherry Blossom',
                productImage: cherryblossom,
                categoryLogo: aromaFinishLogo,
                productDescription: [
                    `Memories of Spring season of Japan, brings forward the fruity - sweet - floral notes of Sakura flowers to any drinks.`
                ],
            },
            {
                id: 'aroma-2',
                slug: 'Aged-Bourbon',
                title: 'Aged Bourbon',
                productImage: agedbourbon,
                categoryLogo: aromaFinishLogo,
                productDescription: [
                    `A well-rounded and true American Bourbon aroma. Hints of Almond, Vanilla, Caramel and Butterscotch.`
                ],
            },
            {
                id: 'aroma-1',
                slug: 'Absinthe',
                title: 'Absinthe',
                productImage: absinthe,
                categoryLogo: aromaFinishLogo,
                productDescription: [
                    `Feel the mystic aroma of an alpine meadow on a mild spring day - soft, spicy and floral.`
                ],
            },

        ],
        productCarousalImages: [aromaCarousal1, aromaCarousal2, aromaCarousal3],
    },
];
import sustain_1 from '../assets/images/sustainability/sustain_1.webp'
import sustain_2 from '../assets/images/sustainability/sustain_2.webp'

export const sustainability = {
    intro: 'Sustainability is a cornerstone of our operations at Sakurafresh, deeply influencing how we interact with our most vital resource: water. Recognizing water not only as the key ingredient in our products but also as a valuable asset to communities, we are committed to ensuring its sustainable management. Our comprehensive approach to "water sustainability" encompasses several strategic initiatives:',
    info: [
        {
            id: 0,
            image: sustain_1,
            title: 'Water Conservation & Groundwater Replenishment',
            content: `We go beyond merely using water in our production processes; we ensure that we give back more than we take. By employing advanced water conservation techniques and focusing on groundwater replenishment, we achieve a replenishment rate of over 100% for the water used in our beverages. Our initiatives include sophisticated rainwater harvesting to recharge aquifers directly and the restoration of pond ecosystems to bolster local biodiversity.`,
        },
        {
            id: 1,
            image: sustain_2,
            title: 'Sustainable Botanical Cultivation',
            content: `In collaboration with First Agro, we've embraced sustainable practices for growing our botanicals and herbs. Through the adoption of drip irrigation methods, we significantly reduce water usage—utilizing less than 10% of the water that traditional farming methods require. This partnership reflects our dedication to minimizing our environmental footprint while ensuring the highest quality of raw materials for our products.`,
        },
    ],
    pointsFocus: {
        title: 'In addition to these focused efforts, Sakurafresh is pioneering in several other areas to enhance our sustainability profile:',
        points: [
            `Renewable Energy Adoption: We're transitioning to renewable energy sources in our production and operation facilities to decrease our carbon footprint and contribute to a cleaner environment.`,
            `Community Engagement and Education: Beyond our corporate practices, we actively engage with local communities to promote water conservation awareness and practices. By educating on sustainable water use and supporting community-led conservation projects, we aim to foster a culture of sustainability.`,
            `Biodiversity Preservation: Our commitment extends to preserving the ecosystems surrounding our operations. By maintaining and enhancing the natural habitats, we support a healthy biodiversity, ensuring that local flora and fauna not only survive but flourish.`,
        ],
    },
    conclusion: `At Sakurafresh, sustainability is not just a policy; it's a principle that guides our everyday decisions and actions. We are dedicated to providing our customers with high-quality beverage products made from raw materials that are sustainably sourced and produced, emphasizing water conservation and ecosystem recharge. Our journey towards environmental stewardship is ongoing, and we are constantly seeking new ways to minimize our impact on the planet while delivering exceptional value to our consumers.`,
};
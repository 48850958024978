import React from 'react'
import { useRecoilState } from 'recoil';
import logo from '../../assets/icons/logo_main.svg'
import waves from '../../assets/icons/sidebar_waves.svg'
import sidebarToggleAtom from '../../recoil/sidebarToggleAtom';
import { Link, NavLink } from 'react-router-dom';
import { sidebarData } from '../../data/sidebarData';

const Sidebar = () => {

    const [sidebarToggle, setSidebarToggle] = useRecoilState(sidebarToggleAtom);

    return (
        <>
            <div className={`fixed top-0 right-0 h-screen p-3 z-40 w-[85vw] md:w-[60vw] lg:w-2/5 xl:w-1/4 ${sidebarToggle ? '' : 'translate-x-full'} transition-all duration-500 ease-in-out`}>
                <div className={`w-full h-full bg-gradient-to-b from-white to-gold-100 border-2 border-gold-600 transition-all duration-500 ease-in-out relative rounded-3xl`}>
                    <div className={`w-full flex justify-end items-center pt-8 pr-5 md:pr-10`}>
                        <Link to={`/`} onClick={() => setSidebarToggle(false)}>
                            <img src={logo} className='w-40 md:w-52' alt='Logo' />
                        </Link>
                    </div>
                    <div className='w-full flex flex-col items-end px-5 md:px-10 pt-16 md:pt-12 gap-4 tracking-[0.5px] md:min-w-80'>
                        {
                            sidebarData?.map((data, i) => (
                                <NavLink key={data?.id} to={data?.route} className={`text-[16px] sm:text-300 text-gray-800 font-semibold hover:opacity-100 opacity-70 transition-opacity duration-300 ease-in-out py-1 z-10`} onClick={() => setSidebarToggle(false)}>{data?.title}</NavLink>
                            ))
                        }
                    </div>
                    <div className='w-full absolute bottom-0 right-0 z-0'>
                        <img src={waves} className='w-full' alt="Waves" />
                    </div>
                </div>
            </div>
            <div className={`fixed w-full h-screen bg-black opacity-0 z-30 ${sidebarToggle ? 'block' : 'hidden'}`} onClick={() => setSidebarToggle(false)} />
        </>
    )
}

export default Sidebar
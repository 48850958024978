import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick'
import PrimaryButton from './PrimaryButton';
import flower from '../../assets/icons/sakura-flowers.svg'
import { categories } from '../../data/allCategories';


const MobileCategoryCarousal = ({ routesArray, productSlug }) => {

    const navigate = useNavigate();

    const { pathname } = useLocation();
    const [currentSlideNumber, setCurrentSlideNumber] = useState(0);

    const reverseRoutesArray = [...routesArray].reverse();

    let settings = {
        initialSlide: pathname?.includes('category') ? 3 : pathname?.includes('liqueurs') ? 2 : 0,
        infinite: true,
        pauseOnHover: false,
        autoplay: true,
        speed: 900,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: pathname?.includes('cocktail-premix') ? 1 : 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: pathname?.includes('cocktail-premix') ? 1 : 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: pathname?.includes('cocktail-premix') ? 1 : 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    useEffect(() => {
        if (pathname?.includes('category')) {
            setCurrentSlideNumber(3);
        } else if (pathname?.includes('liqueurs')) {
            setCurrentSlideNumber(2)
        }
    }, []);

    return (
        <div className={`w-full mx-auto relative flex flex-col justify-center items-center ${pathname?.includes('product') ? 'pt-10 lg:pt-0 h-screen md:h-[95vh]' : pathname?.includes('zero-alcohol-spirits') ? 'h-[75vh]' : 'pt-24'}`}>
            <div className='w-fit h-fit absolute -top-20 right-0'>
                <img src={flower} className='w-[460px]' alt="Sakura flowers" />
            </div>
            {
                !pathname?.includes('zero-alcohol-spirits') && !pathname?.includes('category') ?

                    (
                        <div className='w-full text-4xl lg:text-5xl -translate-y-8 md:-translate-y-20 lg:-translate-y-10 font-playfairDisplaySC text-black text-center tracking-tighter'>
                            <h1 className=''>{categories?.filter((filterData) => filterData?.slug === productSlug)[0]?.title}</h1>
                        </div>
                    ) : null
            }
            <div className='z-10 w-full mx-auto'>
                <Slider {...settings} beforeChange={(currentSlide, nextSlide) => setCurrentSlideNumber(nextSlide)} className=' mx-auto'>
                    {
                        reverseRoutesArray?.map((data, i) => (
                            <div className={`rounded-lg ${pathname?.includes('product/bitters') ? 'w-full' : 'w-fit'}`} key={data?.id}>
                                {
                                    pathname?.includes('bitters') ? (
                                        <div className='mb- text-justify'>
                                            <img src={data?.productImage} className={`w-64 mx-auto ${currentSlideNumber === i ? '' : 'grayscale scale-[60%]'} transition-all duration-300 ease-in-out mb-1 mt-10`} alt="" />
                                        </div>
                                    ) : pathname?.includes('zero-alcohol-spirits') ?
                                        <img src={data?.pinwheelImage} className={`w-48 lg:w-64 mx-auto ${currentSlideNumber === i ? '' : 'grayscale scale-[60%]'} transition-all duration-300 ease-in-out`} alt="" />
                                        : pathname?.includes('category') ?
                                            <img src={data?.categoryImage} className={`w-48 lg:w-64 mx-auto ${currentSlideNumber === i ? '' : 'grayscale scale-[60%]'} transition-all duration-300 ease-in-out`} alt="" />
                                            :
                                            pathname?.includes('cocktail-premix') ?
                                                <div className='mt-5 sm:mt-0 lg:mt-10'>
                                                    <img src={data?.productImage} className={`w-60 lg:w-80 mx-auto ${currentSlideNumber === i ? '' : 'grayscale scale-[60%]'} transition-all duration-300 ease-in-out`} alt="" />
                                                </div>
                                                :
                                                <div className=''>
                                                    <img src={data?.productImage} className={`w-60 lg:w-80 mx-auto ${currentSlideNumber === i ? '' : 'grayscale scale-[60%]'} transition-all duration-300 ease-in-out`} alt="" />
                                                </div>
                                }
                            </div>
                        ))
                    }
                </Slider>
                <div className='relative z-10 pb-5'>
                    <h1 className='text-[32px] md:text-4xl lg:text-5xl font-playfairDisplaySC text-black text-center leading-none tracking-tight mt-5'>{reverseRoutesArray[currentSlideNumber]?.title}</h1>
                    {
                        pathname.includes('category') ?
                            <PrimaryButton btnText={`explore category`} textColor={``} styles={`mx-auto mt-8`} onClick={() => navigate(`/${reverseRoutesArray[currentSlideNumber]?.slug}`)} />
                            :
                            pathname.includes('zero-alcohol-spirits') ?
                                <PrimaryButton btnText={`explore product`} textColor={`text-gold-700`} styles={`mx-auto mt-8`} onClick={() => reverseRoutesArray[currentSlideNumber]?.id === 'liq-05' ? navigate(`/cocktail/${reverseRoutesArray[currentSlideNumber]?.slug}`) : reverseRoutesArray[currentSlideNumber]?.id === 'liq-04' ? navigate(`/spirit/${reverseRoutesArray[currentSlideNumber]?.slug}`) : navigate(`/liqueur/${reverseRoutesArray[currentSlideNumber]?.slug}`)} />
                                :
                                pathname.includes('bitters') ?
                                    <PrimaryButton btnText={`explore product`} textColor={`text-gold-700`} styles={`mx-auto mt-8`} onClick={() => navigate(`/${productSlug}/${reverseRoutesArray[currentSlideNumber]?.slug}`)} />
                                    : <div className='px-10 mt-6 font-semibold text-center text-md lg:text-lg h-[150px]'>
                                        <h1 className=''>{reverseRoutesArray[currentSlideNumber]?.productDescription}</h1>
                                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default MobileCategoryCarousal



function SampleNextArrow(props) {
    const { style, onClick } = props;
    return (
        <div className={`next-slick-arrow z-50 absolute top-1/2 right-8 text-4xl`}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        > {`>`} </div>
    );
}

function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
        <div className={`next-slick-arrow z-50 absolute top-1/2 left-8 text-4xl`}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        > {`<`} </div>
    );
}

import React from 'react'
import { tanchoData } from '../data/tanchoConservation'
import bird from '../assets/icons/bird.svg'
import SecondaryFooter from '../components/global/SecondaryFooter';
import WrappedText from '../components/global/WrappedText';

const TanchoConservationPage = () => {

    const { pageTitle, intro, role, video } = tanchoData;

    return (
        <div>
            <div className='w-full h-screen relative'>
                <video webkit-playsinline="true" playsInline muted={true} autoPlay={true} loop src={video} className='w-full h-screen object-cover opacity-95'></video>
                <div className='w-full absolute bottom-20 md:bottom-28 left-1/2 -translate-x-1/2'>
                    <h1 className='px-5 text-[40px] leading-none md:text-5xl lg:text-7xl font-playfairDisplaySC font-semibold text-center tracking-tight'>{pageTitle}</h1>
                </div>
            </div>
            <div className='px-10 md:px-20 lg:px-44 xl:px-72'>
                <div className='mt-14 md:mt-16'>
                    {
                        intro?.map((data) => (
                            <p key={data} className='mb-2 text-justify font-semibold'> <WrappedText text={data} /></p>
                        ))
                    }
                </div>
                <div className='w-full flex justify-center items-center my-7 md:my-12'>
                    <img src={bird} className='w-32 md:w-40' alt="" />
                </div>
                <div className='flex gap-10 md:gap-10 flex-col-reverse relative z-10'>
                    <div className='w-full'>
                        <h1 className='text-3xl font-semibold mb-6'>{role?.title}</h1>
                        {role?.content?.map((data) => (
                            <p className='mb-4 text-justify font-semibold' key={data}>{data}</p>
                        ))}
                    </div>
                    <div className='flex justify-start lg:justify-center items-center'>
                        <img src={role?.image} className='w-full h-[50vh] md:h-auto object-cover' alt="" />
                    </div>
                </div>
            </div>
            <SecondaryFooter footerText={`Contact Us`} footerLink={`/contact-us`} />
        </div>
    )
}

export default TanchoConservationPage
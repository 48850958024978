import React from 'react';
import SecondaryFooter from '../components/global/SecondaryFooter';
import ribbon from '../assets/icons/ribbon.svg';
import bird from '../assets/icons/bird.svg';
import flower from '../assets/icons/sakura-flowers.svg';
import { ourPhilosophy } from '../data/ourPhilosophyData';
import CorporatePageHeader from '../components/global/CorporatePageHeader';
import sakurafreshWay from '../assets/icons/sakurafreshWay.svg';
import WrappedText from '../components/global/WrappedText';


const OurPhilosophyPage = () => {
  const { heroText, heroImage, intro, pointsImage, points, conslusion } = ourPhilosophy;

  return (
    <div className='relative text-justify'>
      <div className=''>
        <div className='hidden md:block w-fit h-fit absolute top-12 right-0'>
          <img src={flower} className='w-[500px]' alt="Sakura flowers" />
        </div>
        <div className='w-full pt-28 md:pt-32'>
          <CorporatePageHeader headerText={`Shokunin Kishitsu`} styles={`px-6 md:px-0`} />
          <div className='md:w-[700px] mx-auto font-playfairDisplay flex justify-center items-center pb- px-2 md:pb-10'>
            <h1 className='text-center text-2xl sm:text-2xl md:text-4xl tracking-tight font-semibold'>
              <WrappedText text={heroText} />
            </h1>
          </div>
          <div className='w-full flex flex-col gap-5 md:gap-10 items-start justify-normal mt-10 mb-20'>
            <div className='w-full flex justify-start lg:justify-center md:mb-6'>
              <div className='w-full px-10 md:px-20 lg:px-44 xl:px-72 mx-auto relative'>
                <img src={heroImage} className='w-full mx-auto h-96 md:h-auto object-cover z-10 relative' alt="" />
              </div>
            </div>
            <div className='w-full flex justify-center items-center mt-5 md:mt-0'>
              <img src={bird} className='w-32 md:w-40' alt="" />
            </div>
            <div className='w-full gap-12 px-10 md:px-20 lg:px-44 xl:px-72'>
              <div>
                {
                  intro?.map((data, index) => (
                    <div className='mb-5 text-md' key={index}>
                      <p className='gap-2 font-semibold'><WrappedText text={data} /></p>
                    </div>
                  ))
                }
              </div>
              <div className='w-full flex flex-col-reverse gap-16 mt-16 items-center'>
                <div className='flex-1'>
                  {
                    points?.map((data, index) => (
                      <div className='mb-5 text-md' key={index}>
                        <p className='gap-2 flex items-start font-semibold'>
                          <span className='text-sm mt-1'>●</span>&nbsp;
                          <WrappedText text={data} />
                        </p>
                      </div>
                    ))
                  }
                </div>
                <div className=''>
                  <img src={pointsImage} className='w-full md:min-h-[80vh] xl:min-h-max object-cover' alt="" />
                </div>
              </div>
              <div className='mt-10 md:mt-16 relative z-10'>
                {
                  conslusion?.map((data, index) => (
                    <div className='mb-5 text-md font-semibold' key={index}>
                      <p className='gap-2 '><WrappedText text={data} /></p>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <SecondaryFooter footerText={`About Us`} footerLink={`/about-us`} />
    </div>
  );
}

export default OurPhilosophyPage;

import React from 'react'

const CorporatePageHeader = ({ headerText, styles }) => {
  return (
    <h1 className={`text-center flex justify-center font-semibold items-center gap-2 md:gap-5 mb-4 md:mb-8 lg:mb-[70px] text-black text-4xl md:text-5xl font-playfairDisplaySC tracking-tight ${styles}`}>
      <span>{headerText?.split(' ')[0]}</span>
      <span>{headerText?.split(' ')[1]}</span>
    </h1>
  )
}

export default CorporatePageHeader
import about_1 from '../assets/images/about-us/aboutus-hero.jpg'
import about_2 from '../assets/images/about-us/about_2.webp'
import about_3 from '../assets/images/about-us/about_3.webp'
import about_4 from '../assets/images/about-us/about_4.webp'


export const aboutData = {
    heroIamge: about_1,
    heroText: [
        {
            id: 0,
            text: 'Headquartered in the vibrant city of Yokohama, Japan, Sakurafresh Beverage and Food - We are a full-stack manufacturer and blender, offering an extensive range of Alcohol and Zero Alcohol Spirits, Liqueurs, Bitters, Ready-to-Drink Cocktails, and Cocktail Mixers. ',
            points: null,
        },
        {
            id: 1,
            text: 'Our journey began with a commitment to environmentally sustainable and healthy farming practices over a decade ago. Today, Sakurafresh builds on this robust foundation, embracing innovation, diversity, and a wide-ranging selection in our beverage creations. ',
            points: null
        },
        {
            id: 2,
            text: 'At the heart of Sakurafresh is our dedication to the Shokunin Kishitsu ( 職人気質 ) or the craftsman spirit. This principle guides every aspect of our operations, from our partner farms to our blending units, manufacturing sites, and offices worldwide. ',
            points: [
                `We are devoted to crafting unique beverage products and experiences that blend ancient recipes, sustainable botanicals and herbs, and modern blending techniques.`,
                `For us, blending is not merely a meticulous process; it's an art form and a platform expressing endless creativity.`,
                `We remain perpetually open to exploring new possibilities, constantly expanding our horizons in the vast and dynamic world of beverages.`,
            ],
        },
    ],
    bodyText: [
        `This commitment ensures we meet the diverse and evolving preferences of our global markets.`,
        `Sakurafresh's offerings span the entire spectrum of taste and style—from the light, delicate, and elegant to the rich, bold, and smoky. We take pride in our ability to provide an array of Alcohol and Zero Alcohol beverages for daily  enjoyment and celebratory moments alike, boasting the most comprehensive portfolio and numerous global spirits awards.`,
        `Sakurafresh is firmly committed to sustainability and inclusivity, striving to offer a vast array of choices within our beverage portfolio. We aim to foster growth through exceptional craftsmanship, meaningful consumer engagement, and a spirited entrepreneurial approach.`,
    ],
    bodyImages: [
        about_2,
        about_3,
        about_4,
    ],
};
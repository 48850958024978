import React from 'react'
import leftLeaf from '../../assets/icons/goldenleaf-left.svg'
import rightLeaf from '../../assets/icons/goldenleaf-right.svg'

const GlobalAwardsHeader = () => {
    return (
        <div className='w-fit text-darkBrown flex justify-center items-center gap-4 xl:gap-8 z-10 min-w-[300px] md:min-w-[350px]'>
            <span><img src={leftLeaf} className='w-4 xl:w-7' alt="Golden leaf left" /></span>
            <span className='flex flex-col text-300 xl:text-lg text-center tracking-wider leading-tight font-semibold'>
                <h1>Our Global Awards</h1>
                <h1>Winning Collection</h1>
            </span>
            <span><img src={rightLeaf} className='w-4 xl:w-7' alt="Golden leaf right" /></span>
        </div>
    )
}

export default GlobalAwardsHeader
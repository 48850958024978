import React from 'react'

const ContactAddress = ({ title, address }) => {

  return (
    <div className='w-full'>
        <h1 className='text-3xl font-semibold mb-4 font-playfairDisplay'>{title}</h1>
        <div className='flex flex-col items-center'>
        {
          address?.map((data, i) => (
            <p key={i} className='text-md font-semibold'>{data}</p>
          ))
        }
        </div>
    </div>
  )
}

export default ContactAddress
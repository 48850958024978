import React, { useState } from 'react'

const SimpleImageCarousal = ({ images }) => {

    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
    };

    return (
        <div className='w-full relative mt-14 md:mt-24 z-20'>
            <div className='overflow-hidden relative'>
                <div className='flex transition-transform duration-500' style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                    {images?.map((data, index) => (
                        <img src={data} key={index} className='w-full flex-shrink-0' alt="" />
                    ))}
                </div>
            </div>
            <div className='absolute bg-sakuraPink top-1/2 -translate-y-1/2 left-0 flex items-center'>
                <button onClick={prevSlide} className='p-2'>
                    &lt;
                </button>
            </div>
            <div className='absolute bg-sakuraPink top-1/2 -translate-y-1/2 right-0 flex items-center'>
                <button onClick={nextSlide} className='p-2'>
                    &gt;
                </button>
            </div>
        </div>
    )
}

export default SimpleImageCarousal
import React from 'react'
import { Link } from 'react-router-dom'
import SecondaryButton from '../global/SecondaryButton'

const CollectionCategory = ({ image, title, id, textSize, onClick, ctaStyle, route }) => {

    return (
        <div className='flex-1 cursor-pointer group overflow-hidden relative bg-black z-10' onClick={onClick}>
            <img src={image} className='w-full group-hover:scale-[200%] min-h-[30vh] group-hover: group-hover:opacity-50  group-hover:blur-xl transition-all duration-500 ease-in-out object-cover' alt="Cocktails" />
            <div className='w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-1 flex flex-col justify-center items-center'>
                <h1 className={`text-[20px] opacity-0 group-hover:opacity-100 tracking-wide group-hover:text-[20px] leading-none md:group-hover:text-[20px] transition-all duration-500 ease-in-out text-center font-playfairDisplay uppercase font-thin text-white group-hover:text-white`}>{title?.split(' ')[0]}</h1>
                <h1 className={`text-[20px] opacity-0 group-hover:opacity-100 tracking-wide group-hover:text-[20px] leading-none md:group-hover:text-[20px] transition-all duration-500 ease-in-out text-center font-playfairDisplay uppercase font-thin text-white group-hover:text-white`}>{title?.split(' ')[1]}</h1>
            </div>
            <Link to={route}>
                <SecondaryButton buttonText={`Explore`} styles={`absolute left-1/2 font-semibold w-full text-gold-600 text-sm tracking-[1px] hover:text-white -translate-x-1/2 bottom-4 opacity-0 group-hover:opacity-100 uppercase transition duration-500 ease-in-out py-1 md:px-3`} />
            </Link>
        </div>
    )
}

export default CollectionCategory
import shkunin_1 from '../assets/images/our-philosophy/shokunin-1.jpg'
import shkunin_2 from '../assets/images/our-philosophy/shokunin-2.jpg'

export const ourPhilosophy = {
    heroText: 'A Testament to Masterful Craftsmanship & Unceasing Pursuit of Excellence',
    heroImage: shkunin_1,
    intro: [
        `At the core of Sakurafresh's philosophy lies an unbreakable bond with the Japanese tradition of “Shokunin Kishitsu”, embodying the spirit of the art.`,
        `This profound philosophy transcends ability, capability skill or technique; it represents a holistic dedication to lifelong mastery, a journey imbued with purpose, and a deep-rooted passion for our craft.`,
        `Our ethos, deeply intertwined with the concept of Ikigai, celebrates the joy and fulfilment derived from our relentless pursuit of craftsmanship. Inspired by Japan's venerable tradition of continuous refinement, we channel this ethos into every facet of our spirit crafting process, aspiring to create experiences that transcend the ordinary.`,
        `At the heart of our quest for unparalleled excellence stand five foundational pillars, each reflecting our commitment to the art and science of spirit creation:`,
    ],
    pointsImage: shkunin_2,
    points: [
        `Majime (真面目, Seriousness): This pillar embodies our constant focus and dedication to achieving excellence. Our approach, grounded in discipline and a rigorous commitment to quality.`,
        `Kojoshin (向上心, Aspiration for Improvement): Heeding the timeless wisdom of Zen, we acknowledge that the journey towards perfection is infinite. Our ethos is one of perpetual enhancement, embracing each step of the process as an opportunity to refine and elevate our spirits, driven by an unyielding aspiration to surpass our previous achievements`,
        `Seiketsukan (清潔感, Cleanliness): We staunchly believe in the intrinsic link between the purity of our surroundings and the excellence of our spirits. Our unwavering commitment to maintaining a pristine environment is a testament to our dedication to quality, a principle that has earned our beverages international acclaim for their unparalleled freshness and clarity.`,
        `Ganko (頑固, Stubbornness for Excellence): Our journey is navigated by a unique amalgamation of visionary leadership and an indomitable will to achieve our objectives. Through selective team assembly and meticulous mentorship by masters of the craft, we share a collective determination to forge ahead toward excellence.`,
        `Jyonetsu (情熱, Passion): The essence of Sakurafresh pulsates with an intense passion for our work. This zeal is the foundation of our recognition on a global stage, emanating from a heartfelt commitment to mastering our craft, firmly rooted in the belief that without passion, there can be no true artistry.`,
    ],
    conslusion: [
        `Furthermore, in the spirit of the enduring Japanese proverb, 七転び八起き (nanakorobi yaoki) — "fall down seven times, stand up eight" — we embrace every challenge as a catalyst for growth and innovation. Our journey is marked by a rigorous commitment to continuous improvement (kaizen, 改善), a path we traverse with pride and enthusiasm, perpetually striving for the acme of perfection.`,
        `At Sakurafresh, we are more than mere creators of spirits; we are custodians of an ancient traditions, united by our ardour for crafting beverages that resonate with the soul. Join us on this remarkable journey, where each sip is a homage to the enduring legacy of Shokunin Kishitsu - a journey of ceaseless discovery and boundless passion.`,
    ],
};
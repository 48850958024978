import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { categories } from '../data/allCategories';
import SecondaryFooter from '../components/global/SecondaryFooter';
import GlobalAwardsHeader from '../components/global/GlobalAwardsHeader';
import drink from '../assets/icons/drink-icon.svg'
import soda from '../assets/icons/soda-icon.svg'
import stir from '../assets/icons/stir-logo.svg'
import PrimaryButton from '../components/global/PrimaryButton';
import ArcWheelNav from '../components/global/ArcWheelNav';
import SimpleImageCarousal from '../components/global/SimpleImageCarousal';
import MobileCategoryCarousal from '../components/global/MobileCategoryCarousal';
import GlobalPreloader from '../components/global/GlobalPreloader';

const SingleCategoryPage = () => {

    const [categoryData, setCategoryData] = useState();
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1200);

    const params = useParams();
    const navigate = useNavigate();

    const getArcWheelCategories = () => {
        let refinedCategories
        categories?.find((data) => {
            if (data?.slug === params?.id) {
                refinedCategories = data?.products;
            }
            return refinedCategories;
        });
        return refinedCategories
    }

    useEffect(() => {
        categories?.find((data) => {
            if (data?.slug === params?.id) {
                setCategoryData(data);
            }
            return categoryData;
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth >= 1200);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={` relative overflow-hidden ${!categoryData?.title?.includes('Aroma Finish') ? 'pt-20' : 'pt-6'} md:pt-0`}>
            {!categoryData?.slug?.includes('zero-alcohol-spirits') ? <GlobalPreloader /> : null}
            {
                !categoryData?.slug?.includes('zero-alcohol-spirits') && !categoryData?.title?.includes('Aroma Finish') ? (
                    <div className='flex items-center justify-center relative'>
                        <video webkit-playsinline="true" playsInline muted={true} autoPlay={true} loop src=
                            {
                                categoryData?.id === 0 ? 'https://image.sakurafresh.com/all+categories/SF-tonicconcentrates(final).mp4' :
                                    categoryData?.id === 1 ? ' https://image.sakurafresh.com/all+categories/SF-bitters(final).mp4' :
                                        categoryData?.id === 2 ? 'https://image.sakurafresh.com/all+categories/SF-supertarts(final).mp4' :
                                            categoryData?.id === 4 ? 'https://image.sakurafresh.com/all+categories/SF-brix2-japanesegreenmelon(final).mp4' :
                                                categoryData?.id === 5 ? 'https://image.sakurafresh.com/all+categories/SF-brix2-cherryblossom(final).mp4' :
                                                    categoryData?.id === 7 ? 'https://image.sakurafresh.com/other/SF-italianpaloma(final).mp4' : ''
                            }
                            className='w-full flex items-center justify-center opacity-95 z-10'></video>
                    </div>
                ) : null
            }
            {
                categoryData?.title?.includes('Aroma Finish') ? (
                    <div className='md:w-[70%] flex items-center gap-20 md:pt-20 mx-auto'>
                        <div className='hidden md:grid w-full grid-cols-3 gap-7 lg:gap-16 md:mt-20 relative z-0'>
                            {
                                categoryData?.productCarousalImages?.map((data) => (
                                    <img src={data} key={data} className='w-full' alt="" />
                                ))
                            }
                        </div>
                        <div className='block md:hidden'>
                            <SimpleImageCarousal images={categoryData?.productCarousalImages} />
                        </div>
                    </div>
                ) : null
            }
            {
                !categoryData?.slug?.includes('zero-alcohol-spirits') ? (
                    <div className='relative z-10'>
                        <div className={`w-full flex flex-col lg:flex-row gap-20 justify-center items-center pt-10 md:pt-8 lg:pt-10 px-5 md:px-20 lg:px-44 xl:px-44`}>
                            <div className='w-[88%] h-full'>
                                <div className='w-full flex justify-center items-center mb-3 sm:mb-5 lg:mb-10'>
                                    <h1 className={`text-center font-playfairDisplaySC text-4xl md:text-5xl xl:text-6xl tracking-tight md:items-center gap-2 md:gap-4 justify-start leading-7 md:leading-normal flex ${params?.id?.includes('tonic-water-concentrates') ? 'flex-col md:flex-row' : 'flex-row'} mb-5 sm:mb-0`}>
                                        <span className='flex gap-2 justify-center items-center sm:gap-4'>{categoryData?.title?.split(' ')[0]} {categoryData?.title?.split(' ')[0] === 'Tonic' ? <span>Water</span> : null} </span>
                                        <span>{categoryData?.title?.split(' ')[1]}</span>
                                    </h1>
                                </div>
                                <div className='w-full max-w-full mb-10 lg:mb-16'>
                                    {
                                        categoryData?.aboutCategory?.map((data) => (
                                            <p key={data} className='mb-4 text-md text-justify font-semibold'>{data}</p>
                                        ))
                                    }
                                </div>
                                {
                                    categoryData?.iconData ?
                                        (
                                            <div className='flex flex-col md:flex-row justify-center items-center gap-8 pb-16'>
                                                {
                                                    categoryData?.iconData?.map((data, i) => (
                                                        <div key={i} className='w-fit flex flex-col md:flex-row items-center gap-3'>
                                                            {i === 0 ? ('') : (<span className='font-light mr-8 hidden md:block text-5xl'>{'+'}</span>)}
                                                            <img src={i === 0 ? drink : i === 1 ? soda : stir} className={`${i === 0 ? 'w-9' : i === 1 ? 'w-9' : 'w-10'}`} alt="" />
                                                            <p className='text-gold-600 text-sm text-center md:text-left font-semibold max-w-40 leading-tight'>{data?.text}</p>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ) : null
                                }
                            </div>
                        </div>
                        <div className={`w-full z-30 flex justify-center items-center`}>
                            <PrimaryButton btnText={`Explore Our Range`} textColor={`text-black`} onClick={() => navigate(`/product/${params?.id}`)} />
                        </div>
                        {
                            categoryData?.id === 1 || categoryData?.id === 7 ? (
                                <div className='px-10 lg:px-52 lg:pb-10 pt-8 sm:pt-10'>
                                    <div className='w-full flex flex-col gap-3 lg:gap-10 items-center'>
                                        {
                                            categoryData?.awards?.map((data) => (
                                                <div key={data?.id} className='w-fit'>
                                                    <GlobalAwardsHeader title={data?.title} />
                                                    <div className={`hidden lg:flex w-full flex-wrap justify-evenly items-start gap-12 `}>
                                                        {
                                                            data?.awardDetails?.map((sub_data) => (
                                                                <div key={sub_data?.id} className='flex flex-col gap-2 justify-center items-center'>
                                                                    <img src={sub_data?.image} className='w-36' alt="" />
                                                                    <p className='text-md font-bold text-center min-w-40'>{sub_data?.title}</p>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className={`lg:hidden flex flex-wrap justify-center items-center gap-10 md:gap-12 `}>
                                                        {
                                                            data?.awardDetails?.map((sub_data) => (
                                                                <div key={sub_data?.id} className='flex flex-col gap-2 justify-center items-center'>
                                                                    <img src={sub_data?.image} className='w-full max-w-44' alt="" />
                                                                    <p className='text-md font-bold text-center min-w-40'>{sub_data?.title}</p>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                ) : (
                    <>
                        {isWideScreen ? (
                            <ArcWheelNav routesArray={getArcWheelCategories()} enableBlockScroll={true} />
                        ) : (
                            <MobileCategoryCarousal routesArray={categoryData?.products} productSlug={categoryData?.slug} />
                        )}
                    </>
                )
            }
            <div className='w-full flex flex-col justify-center items-center'>
                {
                    categoryData?.id === 7 ? (
                        <>
                            <div className={`hidden md:flex px-10 md:px-20 lg:px-44 xl:px-60 w-fit flex-col md:flex-row justify-center items-center gap-14 justify-items-center mt-20 relative z-[1]`}>
                                {
                                    categoryData?.productCarousalImages?.map((data) => (
                                        <div className='w-fit' key={data}>
                                            <img src={data} className='w-full' alt="" />
                                        </div>
                                    ))
                                }
                            </div>
                            <div className='block md:hidden md:mb-32 relative z-10 px-5'>
                                <SimpleImageCarousal images={categoryData?.productCarousalImages} />
                            </div>
                        </>
                    ) : null
                }
            </div>
            {
                !categoryData?.slug?.includes('zero-alcohol-spirits') ? <SecondaryFooter footerText={`Our Collection`} footerLink={`/category/zero-alcohol`} /> : null
            }
        </div>
    )
}

export default SingleCategoryPage
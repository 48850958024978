import { Route, Routes } from 'react-router-dom';
import PrimaryFooter from './components/global/PrimaryFooter';
import Navbar from './components/global/Navbar';
import LandingPage from './pages/LandingPage';
import CategoryPage from './pages/AllCategoriesPage';
import SingleCategoryPage from './pages/SingleCategoryPage';
import LiqueuerProductPage from './pages/LiqueuerProductPage';
import AboutUsPage from './pages/AboutUsPage';
import OurPhilosophyPage from './pages/OurPhilosophyPage';
import SustainabilityPage from './pages/SustainabilityPage';
import SingleProductPage from './pages/SingleProductPage';
import TanchoConservationPage from './pages/TanchoConservationPage';
import ScrollToTopRouteChange from './components/global/ScrollToTopRouteChange';
import AwardsPage from './pages/AwardsPage';
import ProductPinwheelPage from './pages/ProductPinwheelPage';
import ContactUs from './pages/ContactUs';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  const productRoutes = ['liqueur', 'spirit', 'cocktail'];

  return (
    <div className="relative">
      <Navbar />
      <ScrollToTopRouteChange />
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/category/:id' element={<CategoryPage />} />
        <Route path='/:id' element={<SingleCategoryPage />} />
        <Route path='/:product/:id' element={<SingleProductPage />} />
        {productRoutes.map((route) => <Route key={route} path={`/${route}/:id`} element={<LiqueuerProductPage />} /> )}
        <Route path='/product/:id' element={<ProductPinwheelPage />} />
        <Route path='/about-us' element={<AboutUsPage />} />
        <Route path='/the-sakurafresh-way' element={<OurPhilosophyPage />} />
        <Route path='/sustainability' element={<SustainabilityPage />} />
        <Route path='/awards' element={<AwardsPage />} />
        <Route path='/tancho' element={<TanchoConservationPage />} />
        <Route path='/contact-us' element={<ContactUs />} />
      </Routes>
      <PrimaryFooter />
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ribbon from '../assets/icons/ribbon.svg'
import SecondaryFooter from '../components/global/SecondaryFooter';
import { categories } from '../data/allCategories';
import flower from '../assets/icons/sakura-flowers.svg';
import LiqueurHeader from '../components/global/LiqueurHeader';
import Pagination from '../components/global/Pagination';
import GlobalPreloader from '../components/global/GlobalPreloader';

const LiqueuerProductPage = () => {

  const [categoryData, setCategoryData] = useState();
  const [paginationData, setPaginationData] = useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);


  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth <= 500);

  const params = useParams();
  const navigate = useNavigate();

  const navigateToProduct = (index) => {
    if (index >= 0 && index < paginationData.length) {
      paginationData[index]?.id === 'liq-04' ?
        navigate(`/spirit/${paginationData[index].slug}`)
        : paginationData[index]?.id === 'liq-05' ?
          navigate(`/cocktail/${paginationData[index].slug}`)
          :
          navigate(`/liqueur/${paginationData[index].slug}`)
    }
  }

  const onPrev = () => {
    navigateToProduct(currentPageIndex - 1);
  };
  const onNext = () => {
    navigateToProduct(currentPageIndex + 1)
  };

  useEffect(() => {
    if (categories?.length > 1) {
      categories?.find((data) => {
        if (data?.id === 3) {
          const reversedData = data?.products?.slice()?.reverse();
          setPaginationData(reversedData);
          reversedData?.find((productData, productIndex) => {
            if (productData?.slug === params?.id) {
              setCategoryData(productData);
              setCurrentPageIndex(productIndex);
            }
            return null
          })
        }
        return null
      })
    }
  }, [params]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (

    <div className='overflow-hidden'>
      <GlobalPreloader />
      {
        categoryData?.slug === params?.id ? (
          <div className='w-full pt-28 md:pt-24 px-10 md:px-20 lg:px-32 xl:px-72 relative'>
            <div className='block w-fit h-fit absolute top-5 md:top-20 right-0'>
              <img src={flower} className='w-[460px] relative z-0' alt="Sakura flowers" />
            </div>
            <h1 className='text-center cursor-pointer md:z-30 mb-8 md:mb-12 lg:mb-20 text-darkBrown text-4xl font-playfairDisplaySC flex items-center justify-center gap-4 relative z-10 mr-8' onClick={() => navigate(`/zero-alcohol-spirits`)}> <span className='block font-manrope text-3xl font-semibold'>{`<`}</span>  {categoryData?.categoryName}</h1>
            <div className='w-full flex justify-center items-center'>
              <LiqueurHeader />
            </div>
            <div className='w-full flex flex-col items-center gap-6 md:gap-10 justify-between mt-2 mb-10 lg:mb-20'>
              <div className='w-full mx-auto md:w-full flex justify-center items-center pt-5'>
                <div className='border-2 border-gold-600 p-2'>
                  <video webkit-playsinline="true" playsInline src={categoryData?.video} autoPlay={true} loop={true} muted={true} className='w-full h-[40vh] md:h-auto object-cover z-10 relative'></video>
                </div>
              </div>
              <div className='w-full h-full flex flex-col gap-5 lg:gap-7 z-10 relative'>
                <div className='w-full'>
                  <h1 className='text-[40px] leading-none md:text-6xl font-medium font-playfairDisplay tracking-tight text-center'>{categoryData?.title}</h1>
                </div>
                <div className=''>
                  {
                    categoryData?.aboutProduct?.map((data, index) => (
                      <div key={index} className='mb-5'>
                        {
                          index === 0 && isMobileScreen && categoryData?.id === 'liq-05' ?
                            <p className={`gap-0 text-gold-600 uppercase text-center flex flex-col justify-center items-center font-extrabold ${data?.id === 0 ? 'mb-4 text-xl tracking-wider' : 'mb-0.5 text-md'}`}> <span>{data?.title?.split('(')[0]}</span> <span>&#40;{data?.title?.split('(')[1]}</span></p>
                            :
                            <p className={`gap-2 text-gold-600 uppercase text-center flex flex-col justify-center items-center font-extrabold ${data?.id === 0 ? 'mb-4 text-xl tracking-wider' : 'mb-0.5 text-md'}`}>{data?.title}</p>
                        }

                        {
                          data?.id === 1 && categoryData?.id === 'liq-05' ? (
                            <div className='w-full flex flex-col'>
                              {
                                data?.content?.map((sub_data) => (
                                  <p className='text-md font-semibold text-justify lg:text-center' key={sub_data}>{sub_data}</p>
                                ))
                              }
                            </div>
                          ) : <p className='text-md font-semibold text-justify lg:text-center'>{data?.content}</p>
                        }
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
            <div>
              <div className='w-full flex flex-wrap justify-center items-end gap-14'>
                {
                  categoryData?.productAwards?.map((data, i) => (
                    <div key={data?.id} className='w-fit flex flex-col gap- items-center'>
                      <img src={data?.awardImage} className={`w-36 ${i !== 0 ? 'h-36 object-contain' : ''} ${data?.competition === 'Concours Mondial de Bruxelles 2021' ? 'w-40' : ''}`} alt="" />
                      <h1 className='text-center text-400 md:text-300 font-bold mt-4'>{data?.title}</h1>
                      <div className={`w-full md:w-fit ${``} flex flex-col justify-center items-center`}>
                        <h1 className='text-center text-300 md:text-200 md:w-44 leading-tight font-semibold mt-1'>{data?.competition}</h1>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        ) : null
      }
      <div className='w-full mt-20 lg:mt-20'>
        <div className='w-full flex flex-col md:flex-row items-center justify-center gap-5 px-10 lg:px-36 xl:px-72'>
          {
            categoryData?.productCarousalImages?.map((data) => (
              <div className='w-fit' key={data}>
                <img src={data} className='w-full lg:max-w-[500px]' alt="" />
              </div>
            ))
          }
        </div>
        <div className='w-full flex justify-center items-center mt-16 lg:mt-20 relative z-[1]'>
          <div className='w-full flex flex-col gap-16 lg:gap-14 justify-center items-center px-10'>
            <img src={ribbon} className='w-full max-w-12' alt="" />
            <h1 className='text-3xl text-darkBrown font-playfairDisplay tracking-tight font-semibold'>The Flavour Wheel</h1>
            <img src={categoryData?.flavourWheel} className={`w-full md:w-[80%] lg:w-full ${categoryData?.id === 'liq-01' || categoryData?.id === 'liq-04' ? 'max-w-[800px]' : 'max-w-[700px]'}`} alt="" />
          </div>
        </div>
      </div>
      <div className='w-full flex justify-center items-center z-10 relative mt-16 lg:mt-24 mb-20 pr-6'>
        <Pagination currentPage={currentPageIndex + 1} onNext={onNext} onPrev={onPrev} totalPages={paginationData?.length} />
      </div>
      <SecondaryFooter footerText={`Our Collection`} footerLink={`/category/zero-alcohol`} />
    </div>
  )
}

export default LiqueuerProductPage
import React from 'react'
import goldLeftLeaf from '../../assets/icons/goldenleaf-left.svg'
import goldRightLeaf from '../../assets/icons/goldenleaf-right.svg'

const GlobalAwardsHeader = ({ title }) => {
    return (
        <div className='flex py-6 items-center justify-center gap-5'>
            <img src={goldLeftLeaf} className='w-5' alt="" />
            <h1 className='text-[18px] leading-[20px] md:text-[20px] font-bold text-gold-600 text-center'>{title}</h1>
            <img src={goldRightLeaf} className='w-5' alt="" />
        </div>
    )
}

export default GlobalAwardsHeader
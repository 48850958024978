import React from 'react'
import leftLeaf from '../assets/icons/blackLeaf-left.svg'
import rightLeaf from '../assets/icons/blackLeaf-right.svg'
import flower from '../assets/icons/sakura-flowers.svg'
import { awardsPageData } from '../data/awardsPageData'
import SecondaryFooter from '../components/global/SecondaryFooter'
import video from '../assets/videos/brand-video.webm'
import replaceTextWithLink from '../utils/replaceTextWithLink'
import ScrollToTopButton from '../components/global/ScrollToTopButton'
import GlobalAwardsHeader from '../components/global/GlobalAwardsHeader'

const AwardsPage = () => {

    const { intro, allAwardsData } = awardsPageData; const searchTerm = 'Shokunin Kishitsu ( 職人気質 )';
    const link = '/the-sakurafresh-way';

    // Process the intro text
    const processedIntro = intro.map(text => replaceTextWithLink(text, searchTerm, link));

    return (
        <div className='relative'>
            <div className='hidden md:block w-fit h-fit absolute top-14 right-0'>
                <img src={flower} className='w-[500px]' alt="Sakura flowers" />
            </div>
            <div className='w-full flex justify-center items-center'>
                <div className='flex py-6 md:py-10 pt-32 md:pt-32 items-center gap-7'>
                    <img src={leftLeaf} className='w-6 md:w-8' alt="" />
                    <h1 className='text-4xl md:text-5xl font-semibold tracking-tighter font-playfairDisplay uppercase'>Awards</h1>
                    <img src={rightLeaf} className='w-6 md:w-8' alt="" />
                </div>
            </div>
            <div className='px-10 md:px-20 lg:px-44 xl:px-5'>
                <div className='w-full xl:w-[75%] mx-auto'>
                    {
                        processedIntro?.map((data, i) => (
                            <p key={i} className='mb-4 text-justify md:text-center font-semibold'>{data}</p>
                        ))
                    }
                </div>
                <div className='mt-14'>
                    {
                        allAwardsData?.map((data, i) => (
                            <div key={data?.id} className='mb-16'>
                                <GlobalAwardsHeader title={data?.title} />
                                <div className={` w-[90%] mx-auto flex flex-wrap justify-center items-end ${data?.id === 0 || data?.id === 1 || data?.id === 7 ? 'gap-6 md:gap-10 xl:gap-0' : 'gap-13 xl:gap-10 gap-y-10'} relative z-10`}>
                                    {
                                        data?.awards?.map((sub_data, sub_i) => (
                                            <div key={sub_data?.id} className={`${data?.id === 0 || data?.id === 7 || data?.awards?.length === 6 ? 'min-w-52' : 'min-w-64'} flex flex-col ${sub_i === sub_data?.lenght - 1 ? 'justify-self-center' : ''} gap-4 justify-end items-center`}>
                                                <img src={sub_data?.award} className={`w-40 flex col-center-12 ${i === 1 ? 'w-[200px]' : ''}`} alt="" />
                                                <h1 className='text-center font-bold text-200 md:text-300'>{sub_data?.product}</h1>
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    data?.id === 3 ? (
                                        <div className='md:w-[70%] mx-auto relative mt-24 md:mt-44'>
                                            <video webkit-playsinline="true" playsInline muted={true} autoPlay={true} loop src={video} className='w-full h-[50vh] md:h-auto object-cover opacity-95 z-10'></video>
                                        </div>
                                    ) : null
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
            <ScrollToTopButton />
            <SecondaryFooter footerText={`Sustainability`} footerLink={`/sustainability`} />
        </div>
    )
}

export default AwardsPage
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { categories } from '../data/allCategories';
import flower from '../assets/icons/sakura-flowers.svg';
import SecondaryFooter from '../components/global/SecondaryFooter';
import Pagination from '../components/global/Pagination';

const SingleProductPage = () => {
    const [productData, setProductData] = useState();
    const [categoryData, setCategoryData] = useState({
        name: '',
        id: '',
    });
    const [paginationData, setPaginationData] = useState([]);
    const [currentProductIndex, setCurrentProductIndex] = useState(0);

    const params = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const navigateToProduct = (index) => {
        if (index >= 0 && index < paginationData.length) {
            navigate(`/${params?.product}/${paginationData[index].slug}`);
        }
    };

    const handleNext = () => {
        navigateToProduct(currentProductIndex + 1);
    };

    const handlePrev = () => {
        navigateToProduct(currentProductIndex - 1);
    };

    useEffect(() => {
        const handleFindProduct = (index) => {
            const selectedCategory = categories[index];
            setCategoryData({
                name: selectedCategory?.title,
                id: selectedCategory?.id,
                slug: selectedCategory?.slug,
            });

            const reversedData = selectedCategory?.products?.slice()?.reverse();
            setPaginationData(reversedData);

            const foundProduct = reversedData.find(data => data?.slug === params?.id);
            if (foundProduct) {
                foundProduct.productSlug = selectedCategory?.slug;
                setProductData(foundProduct);
            }
        };
        const productSlugLocal = params?.product;
        if (productSlugLocal) {
            switch (productSlugLocal) {
                case 'tonic-water-concentrates':
                    handleFindProduct(1);
                    break;
                case 'bitters':
                    handleFindProduct(3);
                    break;
                case 'super-tarts':
                    handleFindProduct(2);
                    break;
                case 'common-syrups':
                    handleFindProduct(5);
                    break;
                case 'premium-syrups':
                    handleFindProduct(6);
                    break;
                case 'aroma-finish':
                    handleFindProduct(7);
                    break;
                case 'cocktail-premix':
                    handleFindProduct(0);
                    break;
                default:
                    break;
            }
        }
    }, [params]);

    useEffect(() => {
        if (paginationData?.length) {
            const index = paginationData.findIndex(product => product.slug === params?.id);
            setCurrentProductIndex(index !== -1 ? index : 0);
        }
    }, [paginationData, params]);

    return (
        <div className={`relative pt-24 lg:pt-8 min-h-screen overflow-hidden`}>
            <div className='hidden md:block w-fit h-fit absolute top-10 right-0 z-0'>
                <img src={flower} className='w-[460px]' alt="Sakura flowers" />
            </div>
            <h1 className='text-center cursor-pointer text-darkGreen text-4xl font-playfairDisplaySC my-6 lg:my-16 z-[5] md:z-30 relative flex items-center justify-center gap-4 px-2 mr-6' onClick={() => navigate(`/${categoryData?.slug}`)}> <span className='font-manrope text-3xl font-semibold'>{`<`}</span>{categoryData?.name}</h1>
            {
                pathname?.includes('bitters') ?
                    <div className='pb-3 flex w-full justify-center items-center z-10 relative'>
                        {
                            !productData?.video ? (
                                <div className='p-2'>
                                    <img src={productData?.productImage} className='w-[70%] mx-auto' alt="" />
                                </div>
                            ) : (
                                <div className='p-2'>
                                    <video webkit-playsinline="true" playsInline src={productData?.video} className='w-full max-w-[800px]' muted={true} loop={true} autoPlay={true}></video>
                                </div>
                            )
                        }
                    </div> : ''
            }
            <div className='w-[80%] md:w-[60%] xl:w-[45%] mx-auto pt-6 relative z-[5]'>
                <h1 className='text-center font-semibold mb-8 text-darkGreen text-4xl font-playfairDisplay'>{productData?.title} {categoryData?.name?.split(' ')[0]}</h1>
                <div className='flex flex-col justify-start'>
                    {
                        productData?.productDescription?.map((data) => (
                            <p key={data} className={`text-darkGreen ${pathname?.includes('bitters') ? 'text-justify' : 'text-center'} font-semibold`}>{data}</p>
                        ))
                    }
                </div>
                {
                    pathname?.includes('bitters') ? (
                        <div className='grid grid-cols-1 lg:grid-cols-2 mt-10 mb-10 gap-y-10 lg:gap-x-20'>
                            {
                                productData?.aboutProduct?.map((data, i) => (
                                    <div key={i} className={`text-darkGreen text-justify ${i === 2 ? 'lg:col-start-1 lg:col-end-3' : ''}`}>
                                        <h1 className='text-md uppercase text-gold-600 font-bold mb-1'>{data?.title}</h1>
                                        <h1 className='text-md text- font-semibold'>{data?.content}</h1>
                                    </div>
                                ))
                            }
                        </div>
                    ) : null
                }
            </div>
            {
                !pathname?.includes('bitters') ?
                    <div className='pb-16 flex w-full justify-center items-center'>
                        <div className='p-2'>
                            <img src={productData?.productImage} className='w-72 relative z-10' alt="" />
                        </div>
                    </div> : ''
            }
            <div className='w-full flex justify-center items-center z-10 relative mt-20 pr-7'>
                <Pagination
                    currentPage={currentProductIndex + 1}
                    totalPages={paginationData?.length}
                    onNext={handleNext}
                    onPrev={handlePrev}
                />
            </div>
            <SecondaryFooter footerText={`Our Collection`} footerLink={`/category/zero-alcohol`} />
        </div>
    );
};

export default SingleProductPage;


import wscGold from '../assets/images/awards/liqeuers/san-fransisco-wsc/wsc-gold.png'
import wscSingleGold from '../assets/images/awards/liqeuers/san-fransisco-wsc/wsc-singlegold.png'

import ssGold from '../assets/images/awards/liqeuers/spiritselection/ss-gold.png'
import ssSilver from '../assets/images/awards/liqeuers/spiritselection/ss-silver.png'

import iwscSilver from '../assets/images/awards/liqeuers/iwsc/iwsc-silver.png'
import iwscBronze from '../assets/images/awards/liqeuers/iwsc/iwsc-bronze.png'

import lscSilver from '../assets/images/awards/liqeuers/lsc/lsc-silver.png'
import lscBronze from '../assets/images/awards/liqeuers/lsc/lsc-bronze.png'

import bartenderGold from '../assets/images/awards/liqeuers/bartender/bartender-gold.png'
import bartenderSilver from '../assets/images/awards/liqeuers/bartender/bartender-silver.png'
import bartenderBronze from '../assets/images/awards/liqeuers/bartender/bartender-bronze.png'

import iscSilver from '../assets/images/awards/liqeuers/isc/isc-silver.svg'
import iscBronze from '../assets/images/awards/liqeuers/isc/isc-bronze.svg'

import sbMaster from '../assets/images/awards/liqeuers/sb/sb-master.svg'
import sbGold from '../assets/images/awards/liqeuers/sb/sb-gold.svg'
import sbSilver from '../assets/images/awards/liqeuers/sb/sb-silver.svg'
import sbBronze from '../assets/images/awards/liqeuers/sb/sb-bronze.png'

import cwsaGold from '../assets/images/awards/liqeuers/cwsa/cwsa-gold.png'
import cwsaSilver from '../assets/images/awards/liqeuers/cwsa/cwsa-silver.png'
import cwsaBronze from '../assets/images/awards/liqeuers/cwsa/cwsa-bronze.png'

import bestOfClass from '../assets/images/awards/liqeuers/BOC/best-of-class.png'


export const awardsPageData = {
    intro: [
        `Shokunin Kishitsu ( 職人気質 ), the Sakurafresh Way is the heart of everything we do which enabled us to innovate and craft global spirits awards winner portfolio.`,
        `Sakurafresh is winner of sixty prestigious medals at San Francisco World Spirits Competition, Concours Mondial de Bruxelles, International Wine & Spirit Competition (IWSC), London Spirits Competition (LSC), International Spirits Challenge (ISC), The Bartender Spirits Awards (BSA), The Global Spirits Masters and China Wines & Spirits (CWSA) competitions.`,
    ],
    allAwardsData: [
        {
            id: 0,
            title: 'San Francisco World Spirits Competition',
            awards: [
                {
                    id: 'award-1',
                    product: 'Italian Red Bitter',
                    award: bestOfClass,
                },
                {
                    id: 'award-2',
                    product: 'Amaretto',
                    award: wscGold,
                },
                {
                    id: 'award-3',
                    product: 'Italian Red Bitter',
                    award: wscGold,
                },
                {
                    id: 'award-4',
                    product: 'Amaro Italiano',
                    award: wscSingleGold,
                },
            ],
        },
        {
            id: 1,
            title: 'Concours Mondial de Bruxelles',
            awards: [
                {
                    id: 'award-1',
                    product: 'Amaretto',
                    award: ssGold,
                },
                {
                    id: 'award-2',
                    product: 'Negroni',
                    award: ssGold,
                },
                {
                    id: 'award-3',
                    product: 'Ron Añejo',
                    award: ssSilver,
                },
            ],
        },
        {
            id: 2,
            title: 'International Wine & Spirit Competition (IWSC)',
            awards: [
                {
                    id: 'award-1',
                    product: 'Sakura Umeshu',
                    award: iwscSilver,
                },
                {
                    id: 'award-2',
                    product: 'Mole Bitters',
                    award: iwscSilver,
                },
                {
                    id: 'award-3',
                    product: 'Marrakesh',
                    award: iwscSilver,
                },
                {
                    id: 'award-4',
                    product: 'Amaretto',
                    award: iwscBronze,
                },
                {
                    id: 'award-5',
                    product: 'Amaro Italiano',
                    award: iwscBronze,
                },
                {
                    id: 'award-6',
                    product: 'Ron Oscuro Rum',
                    award: iwscBronze,
                },
                {
                    id: 'award-7',
                    product: 'Reposado',
                    award: iwscBronze,
                },
                {
                    id: 'award-8',
                    product: 'Italian Red Bitter',
                    award: iwscBronze,
                },
                {
                    id: 'award-9',
                    product: 'Aromatic XI',
                    award: iwscBronze,
                },
                {
                    id: 'award-10',
                    product: 'Pink Paloma Premix',
                    award: iwscBronze,
                },
                {
                    id: 'award-11',
                    product: 'Cuba Libre With Yuzu',
                    award: iwscBronze,
                },
            ],
        },
        {
            id: 3,
            title: 'London Spirits Competition (LSC)',
            awards: [
                {
                    id: 'award-1',
                    product: 'Amaro Italiano',
                    award: lscSilver,
                },
                {
                    id: 'award-2',
                    product: 'Italian Red Bitter',
                    award: lscSilver,
                },
                {
                    id: 'award-3',
                    product: 'Reposado',
                    award: lscSilver,
                },
                {
                    id: 'award-4',
                    product: 'Aromatic XI',
                    award: lscSilver,
                },
                {
                    id: 'award-5',
                    product: 'Aromatic XIII',
                    award: lscSilver,
                },
                {
                    id: 'award-6',
                    product: 'Mole Bitters',
                    award: lscSilver,
                },
                {
                    id: 'award-7',
                    product: 'Marrakesh',
                    award: lscSilver,
                },
                {
                    id: 'award-8',
                    product: 'Japanese Matcha',
                    award: lscSilver,
                },
                {
                    id: 'award-9',
                    product: 'Amaretto',
                    award: lscBronze,
                },
                {
                    id: 'award-10',
                    product: 'Negroni',
                    award: lscBronze,
                },
            ],
        },
        {
            id: 4,
            title: 'Bartender Spirits Awards (BSA)',
            awards: [
                {
                    id: 'award-1',
                    product: 'Italian Red Bitter',
                    award: bartenderGold,
                },
                {
                    id: 'award-2',
                    product: 'Reposado',
                    award: bartenderSilver,
                },
                {
                    id: 'award-3',
                    product: 'Amaro Italiano',
                    award: bartenderSilver,
                },
                {
                    id: 'award-4',
                    product: 'Amaretto',
                    award: bartenderSilver,
                },
                {
                    id: 'award-5',
                    product: 'Aromatic XI Bitters',
                    award: bartenderSilver,
                },
                {
                    id: 'award-6',
                    product: 'Lotus Bitters',
                    award: bartenderSilver,
                },
                {
                    id: 'award-7',
                    product: 'Japanese Matcha',
                    award: bartenderSilver,
                },
                {
                    id: 'award-8',
                    product: 'Yuzu Citrus',
                    award: bartenderSilver,
                },
                {
                    id: 'award-9',
                    product: 'Chrysanthemum',
                    award: bartenderSilver,
                },
                {
                    id: 'award-10',
                    product: 'Marrakesh',
                    award: bartenderSilver,
                },
                {
                    id: 'award-11',
                    product: 'Tonka Beans Bitters',
                    award: bartenderSilver,
                },
                {
                    id: 'award-12',
                    product: 'Mole Bitters',
                    award: bartenderSilver,
                },
                {
                    id: 'award-13',
                    product: 'Negroni',
                    award: bartenderBronze,
                },
            ],
        },
        {
            id: 5,
            title: 'International Spirits Challenge Awards',
            awards: [
                {
                    id: 'award-1',
                    product: 'Italian Red Bitter',
                    award: iscSilver,
                },
                {
                    id: 'award-2',
                    product: 'Amaretto',
                    award: iscSilver,
                },
                {
                    id: 'award-3',
                    product: 'Negroni',
                    award: iscSilver,
                },
                {
                    id: 'award-4',
                    product: 'Amaro Italiano',
                    award: iscBronze,
                },
                {
                    id: 'award-5',
                    product: 'Marrakesh',
                    award: iscBronze,
                },
                {
                    id: 'award-6',
                    product: 'Yuzu Citrus Bitters',
                    award: iscBronze,
                },
            ],
        },
        {
            id: 6,
            title: 'The Global Spirits Masters Medals',
            awards: [
                {
                    id: 'award-1',
                    product: 'Amaretto',
                    award: sbMaster,
                },
                {
                    id: 'award-2',
                    product: 'Amaro Italiano',
                    award: sbGold,
                },
                {
                    id: 'award-3',
                    product: 'Italian Red Bitter',
                    award: sbSilver,
                },
                {
                    id: 'award-4',
                    product: 'Lotus Bitters',
                    award: sbSilver,
                },
                {
                    id: 'award-5',
                    product: 'Mole Bitters',
                    award: sbSilver,
                },
                {
                    id: 'award-6',
                    product: 'Sakura Sake',
                    award: sbBronze,
                },
            ],
        },
        {
            id: 7,
            title: 'China Wines & Spirits (CWSA) Awards',
            awards: [
                {
                    id: 'award-1',
                    product: 'Reposado',
                    award: cwsaGold,
                },
                {
                    id: 'award-2',
                    product: 'Negroni',
                    award: cwsaGold,
                },
                {
                    id: 'award-3',
                    product: 'Italian Red Bitter',
                    award: cwsaSilver,
                },
                {
                    id: 'award-4',
                    product: 'Amaro Italiano',
                    award: cwsaBronze,
                },
            ],
        },
    ],
};
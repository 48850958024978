import facebook from '../assets/icons/social-media/facebook-dark.svg'
import twitter from '../assets/icons/social-media/twitter-X-dark.svg'
import instagram from '../assets/icons/social-media/instagram-dark.svg'
import linkedin from '../assets/icons/social-media/linkedin-dark.svg'

export const footerData = {
    copyright: '© 2014-24 Sakurafresh. All Rights Reserved.',
    socialMedia: [
        {
            id: 0,
            title: 'Facebook',
            link: 'https://www.facebook.com/sakurafresh',
            icon: facebook
        },
        {
            id: 1,
            title: 'Instagram',
            link: 'https://www.instagram.com/sakura.fresh?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
            icon: instagram
        },
        {
            id: 2,
            title: 'X',
            link: 'https://twitter.com/Sakurafres9094',
            icon: twitter
        },
        {
            id: 3,
            title: 'LinkedIn',
            link: 'https://www.linkedin.com/company/sakurafresh/mycompany/',
            icon: linkedin
        },
    ],
    redirects: [
        {
            id: 0,
            title: 'Terms & Conditions',
            route: '/terms&conditions'
        },
        {
            id: 1,
            title: 'Legal Policy',
            route: '/legal-policy'
        },
    ],
};

export const categoryPageFooterData = {
    footerHeading: `Let's talk bulk!`,
    copyright: '© 2014-24 Sakurafresh. All Rights Reserved.',
    socialMedia: [
        {
            id: 0,
            title: 'Facebook',
            link: 'https://www.facebook.com/sakurafresh',
            icon: facebook
        },
        {
            id: 1,
            title: 'Instagram',
            link: 'https://www.instagram.com/sakura.fresh?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
            icon: instagram
        },
        {
            id: 2,
            title: 'X',
            link: 'https://twitter.com/Sakurafres9094',
            icon: twitter
        },
        {
            id: 3,
            title: 'LinkedIn',
            link: 'https://www.linkedin.com/company/sakurafresh/mycompany/',
            icon: linkedin
        },
    ],
    redirects: [
        {
            id: 0,
            title: 'Terms & Conditions',
            route: '/terms&conditions'
        },
        {
            id: 1,
            title: 'Legal Policy',
            route: '/legal-policy'
        },
    ],
};
const WrappedText = ({ text }) => {
    const wrapJapaneseText = (text) => {
        const isJapanese = (char) => {
            const unicodeBlock = char.charCodeAt(0);
            return (
                (unicodeBlock >= 0x3040 && unicodeBlock <= 0x30FF) || // Hiragana and Katakana
                (unicodeBlock >= 0x4E00 && unicodeBlock <= 0x9FBF)   // Kanji
            );
        };

        const wrappedText = text?.split('').map((char, index) =>
            isJapanese(char) ? `<span class="font-extrabold text-[18px]" key=${index}>${char}</span>` : char
        ).join('');

        return wrappedText;
    };

    const wrappedText = wrapJapaneseText(text);

    return <span dangerouslySetInnerHTML={{ __html: wrappedText }} />;
};

export default WrappedText;
import React from 'react'
import flower from '../assets/icons/sakura-flowers.svg'
import SecondaryFooter from '../components/global/SecondaryFooter'
import { aboutData } from '../data/aboutusData'
import CorporatePageHeader from '../components/global/CorporatePageHeader'
import replaceTextWithLink from '../utils/replaceTextWithLink'
import SimpleImageCarousal from '../components/global/SimpleImageCarousal'

const AboutUsPage = () => {

    const { heroText, bodyImages, bodyText, heroIamge } = aboutData;
    const searchTerm = 'Shokunin Kishitsu ( 職人気質 )';
    const link = '/the-sakurafresh-way';

    const processedHeroText = heroText.map(({ id, text, points }) => ({
        id,
        text: replaceTextWithLink(text, searchTerm, link),
        points: points ? points.map(point => replaceTextWithLink(point, searchTerm, link)) : null
    }));

    const processedBodyText = bodyText?.map(text => replaceTextWithLink(text, searchTerm, link));

    return (
        <div className='relative text-justify'>
            <div className='px-10 md:px-20 lg:px-44 xl:px-72'>
                <div className='hidden md:block w-fit h-fit absolute top-16 right-0'>
                    <img src={flower} className='w-[500px]' alt="Sakura flowers" />
                </div>
                <div className='w-full pt-28 md:pt-32'>
                    <CorporatePageHeader headerText={`About Us`} />
                    <div className='w-full flex flex-col gap-10 md:gap-16 items-center justify-normal xl:mb-10'>
                        <div className='w-full flex justify-start lg:justify-center'>
                            <div className=''>
                                <img src={heroIamge} className='w-90 h-[50vh] md:h-auto object-left object-cover md:w-full z-10 relative' alt="" />
                            </div>
                        </div>
                        <div className='w-full h-full flex flex-col gap-12'>
                            <div>
                                {
                                    processedHeroText?.map((data) => (
                                        <div key={data?.id} className='mb-5 text-md font-semibold'>
                                            <p className={`gap-2 `}>{data?.text}</p>
                                            {
                                                data?.points ? (
                                                    <div className='flex flex-col justify-center items-start gap-1 mt-5'>
                                                        {
                                                            data?.points?.map((sub_data, i) => (
                                                                <span key={i} className='flex items-start mb-4 gap-3 '> <p className='text-xs mt-1'>{`●`}</p> {sub_data}</span>
                                                            ))
                                                        }
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        {
                            processedBodyText?.map((data, i) => (
                                <h1 key={i} className='mb-5 font-semibold'>{data}</h1>
                            ))
                        }
                    </div>
                    <div className='hidden md:grid w-full grid-cols-3 gap-16 mt-14 md:mt-24 relative z-20'>
                        {
                            bodyImages?.map((data) => (
                                <img src={data} key={data} className='w-full' alt="" />
                            ))
                        }
                    </div>
                    <div className='block md:hidden'>
                        <SimpleImageCarousal images={bodyImages} />
                    </div>
                </div>
            </div>
            <SecondaryFooter footerText={`Global Spirits Awards`} footerLink={`/awards`} />
        </div>
    )
}

export default AboutUsPage
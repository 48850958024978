import React from 'react'
import logo_main from '../../assets/icons/logo_main.svg'
import menu_icon from '../../assets/icons/hamburger_grey.svg'
import sidebarToggleAtom from '../../recoil/sidebarToggleAtom'
import { useLocation } from 'react-router';
import { useRecoilState } from 'recoil';
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom';

const Navbar = () => {

    const { pathname } = useLocation();

    const [sidebarToggle, setSidebarToggle] = useRecoilState(sidebarToggleAtom);

    return (
        <>
            <div className={`desktop-nav fixed top-0 right-0 w-full z-20 hidden md:block ${pathname === '/' ? 'pt-6' : 'pt-12'}`}>
                <div className={`w-[93%] mx-auto relative flex ${pathname === '/' ? 'justify-center' : 'justify-end'} items-center`}>
                    <Link to={`/category/zero-alcohol`}><img src={logo_main} alt='Main Logo' className={`w-full max-w-32 xl:max-w-44 duration-500 transition-all ease-in-out`} /></Link>
                    {pathname === '/' ? '' : (
                        <Link className='text-lg text-black font-500 absolute right-0 -bottom-8 p-1'>
                            <img src={sidebarToggle ? '' : menu_icon} className='w-6' onClick={() => setSidebarToggle(true)} alt="" />
                        </Link>
                    )}
                </div>
            </div>
            <div className={`fixed top-0 right-0 w-full z-30 block md:hidden py-3 glass-morphism`}>
                <div className={`w-[93%] mx-auto relative flex justify-center items-center`}>
                    <Link to={`/category/zero-alcohol`}><img src={logo_main} alt='Main Logo' className={`w-full max-w-32 duration-500 transition-all ease-in-out`} /></Link>
                    {pathname === '/' ? '' : (
                        <Link className='text-lg text-black font-500 absolute right-0 -bottom-'>
                            <img src={sidebarToggle ? '' : menu_icon} className='w-6' onClick={() => setSidebarToggle(true)} alt="" />
                        </Link>
                    )}
                </div>
            </div>
            <Sidebar />
        </>
    )
}

export default Navbar
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { footerData } from '../../data/footerData'

const Footer = () => {

    const { pathname } = useLocation();

    const navigate = useNavigate();

    const {socialMedia, redirects, copyright} = footerData;

  return (
    <div className={`w-full relative z-10 mt-5 md:mt-0 md:fixed bottom-0 right-0 ${pathname === '/' ? 'block' : 'hidden'} bg-white`}>
        <div className='w-[90%] flex flex-col md:flex-row items-center justify-between gap-5 md:gap-0 py-3 pb-5 mx-auto'>
            <div className='flex items-center gap-12 md:gap-6'>
                {
                    socialMedia?.map((data) => (
                        <a key={data?.id} href={data?.link} target='blank'><img src={data?.icon} className={`${data?.id === 0 ? 'w-2.5' : 'w-4'}`} alt={data?.title} /></a>
                    ))
                }
            </div>
            <div className='text-center'>
                <p className='text-sm font-semibold'>{copyright}</p>
            </div>
        </div>
    </div>
  )
}

export default Footer
import React, { useEffect, useState } from 'react'
import logo_main from '../../assets/icons/logo_main_white.svg'
import video from '../../assets/videos/brand-video.webm'

const LandingPagePreLoader = () => {

  const [ageVerified, setAgeVerified] = useState(null);

  const [preLoaderActive, setPreLoaderActive] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPreLoaderActive(false)
    }, 2500);
  }, []);

  const ageVerificationSuccess = () => {
    setAgeVerified('clicked')
    setTimeout(() => {
      setAgeVerified(true)
    }, 3000);
  };

  return (
    <div className={`w-full shadow-md z-50 fixed top-0 left-0 right-0 bg-white overflow-hidden ${ageVerified === true ? 'h-0' : 'h-screen'} duration-500 transition-all ease-in-out`}>
      <div className='w-full min-h-screen flex flex-col justify-center items-center relative'>
        <span className='absolute inset-0 w-full z-0'>
          <div className='w-full h-screen relative'>
            <div className='w-full h-full absolute bg-black opacity-50 z-20'></div>
            <video webkit-playsinline="true" muted={true} autoPlay={true} playsInline loop src={video} className='w-full h-screen object-cover opacity-95 z-10'></video>
          </div>
        </span>
        <img src={logo_main} alt='Main Logo' className={`w-full z-20  ${preLoaderActive ? 'animate-pulse max-w-80' : '-translate-y-60 max-w-64 md:max-w-72'} duration-1000 transition-all ease-in-out`} />
        <div className={`w-[90%] md:w-fit absolute left-1/2 -translate-x-1/2 bottom-32 md:bottom-[10vh] flex flex-col z-60 items-center gap-10 overflow-hidden`}>
          <div className={`w-full p-5 md:h-40 transition-all flex flex-col justify-between duration-700 ease-in-out ${preLoaderActive ? 'translate-y-44' : ''}`}>
            <div className='w-full'>
              {
                ageVerified === null ? (
                  <p className=' text-center text-xl md:text-2xl font-medium text-white min-w-[300px] '>Are you of legal drinking age in your country ?</p>
                ) : null
              }
            </div>
            {
              ageVerified === null ? (
                <div className='w-full pt-5 flex items-center justify-center gap-28'>
                  <button className='text-white text-xl md:text-2xl hover:font-bold hover:underline transition-all duration-300 ease-in-out' onClick={ageVerificationSuccess}>Yes</button>
                  <button className='text-white text-xl md:text-2xl hover:font-bold hover:underline transition-all duration-300 ease-in-out' onClick={() => setAgeVerified(false)}>No</button>
                </div>
              ) : ageVerified === 'clicked' ? (
                <p className='text-center text-green-500 flex items-center justify-center gap-3 text-xl md:text-2xl'>Loading <span className='w-2 h-2 rounded-full bg-green-500 animate-ping'></span></p>
              ) : ageVerified === false ? (
                <p className='text-center text-red-500 font-semibold text-xl md:text-2xl'>Enjoy growing up and see you when you are of legal age to drink.</p>
              ) : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPagePreLoader
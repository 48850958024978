import React from 'react'

const CategoryPannel = ({ image, title, id, textSize, onClick, ctaStyle, active }) => {

    return (
        <div className={`flex-1 cursor-pointer group overflow-hidden relative bg-black ${active ? '' : 'grayscale'}`} onClick={onClick}>
            <img src={image} className='w-full group-hover:scale-[200%] min-h- max-h-[38vh] lg:min-h-[70vh] xl:min-h-[75vh] group-hover:blur-2xl group-hover:opacity-50 sm:group-hover:blur-xl transition-all duration-500 ease-in-out object-cover z-10 relative' alt="Cocktails" />
            <h1 className={`absolute hidden lg:block text-800 lg:text-900 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full text-center font-playfairDisplaySC font-thin tracking-tighter text-white ${active ? 'group-hover:text-gold-600' : 'group-hover:text-gray-400'} flex flex-col items-center justify-center gap-3 w-fit`}>
                {title}
                {
                    active ? null : (
                        <div className='text-600 font-normal font-manrope tracking-normal mt-5'>
                            coming soon
                        </div>
                    )
                }
            </h1>
            <h1 className={`block lg:hidden text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 text-5xl md:text-7xl transition-all duration-300 ease-in-out font-playfairDisplaySC tracking-tight w-full text-center ${active ? 'text-gold-600' : 'text-gray-400'} font-medium`}>{title}
                {
                    active ? null : (
                        <div className='text-500 font-normal font-manrope tracking-normal mt-5'>
                            coming soon
                        </div>
                    )
                }
            </h1>
        </div>
    )
}

export default CategoryPannel
import React, { useEffect, useState } from 'react'
import SecondaryFooter from '../components/global/SecondaryFooter'
import AwardWinnerCategory from '../components/allcategoriespage/AwardWinnerCategory'
import CollectionCategory from '../components/allcategoriespage/CollectionCategory'
import gate from '../assets/icons/gate.svg'
import { useLocation } from 'react-router-dom'
import { categoryPageData } from '../data/categoryPageData'
import LiqueurHeader from '../components/global/LiqueurHeader'
import { categories } from '../data/allCategories'
import ArcWheelNav from '../components/global/ArcWheelNav'
import MobileCategoryCarousal from '../components/global/MobileCategoryCarousal'

const AllCategoriesPage = () => {

  const { pathname } = useLocation();

  const [categoryData, setCategoryData] = useState({});
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1200);

  useEffect(() => {
    if (pathname === '/category/alcohol') {
      setCategoryData(categoryPageData[0]);
    } else {
      setCategoryData(categoryPageData[1]);
    }
  }, [pathname])

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1200);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='w-full relative '>
      {isWideScreen ? (
        <ArcWheelNav routesArray={categories} enableBlockScroll={true} />
      ) : (
        <MobileCategoryCarousal routesArray={categories} />
      )}
      <div className='w-full pb-10 md:pb-10'>
        {
          pathname === '/category/zero-alcohol' ? (
            <div className='w-full pt-20'>
              <div className='w-full flex justify-center items-center'>
              <LiqueurHeader />
              </div>
              <div className='w-[80%] mx-auto flex flex-col xl:flex-row gap-8 md:gap-20 mt-7 md:mt-16 z-10 relative'>
                {
                  categoryData?.awardWinners?.map((data) => (
                    <AwardWinnerCategory key={data?.id} id={data?.id} image={data?.image} title={data?.title} textSize={`50px`} route={`/${data?.slug}`} />
                  ))
                }
              </div>
              <div className='w-full flex justify-center items-center mt-28 md:mt-36'>
                <span className='flex flex-col items-center gap-8 text-xl font-500'>
                  <img src={gate} className='w-16' alt="Gate Icon" />
                  <h1 className='text-[30px] md:text-4xl font-playfairDisplay font-semibold text-darkBrown tracking-tight'>Other Collections</h1>
                </span>
              </div>
              <div className='w-[80%] mx-auto grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 md:gap-10 mt-10 xl:mt-12'>
                {
                  categoryData?.allCollections?.map((data) => (
                    <CollectionCategory key={data?.id} id={data?.id} image={data?.image} title={data?.title} textSize={`20px`} ctaStyle={`text-100`} route={`/${data?.slug}`} />
                  ))
                }
              </div>
            </div>
          ) : null
        }
      </div>
      <SecondaryFooter footerText={`The Sakurafresh Way`} footerLink={`/the-sakurafresh-way`} />
    </div>
  )
}

export default AllCategoriesPage
import React from 'react'
import mountFuji from '../../assets/icons/mount-fuji.svg'
import SecondaryButton from './SecondaryButton'
import ribbon from '../../assets/icons/ribbon.svg'
import sakura from '../../assets/icons/sakura-flowers-2.svg'
import { Link, useLocation } from 'react-router-dom'
import { categoryPageFooterData } from '../../data/footerData'

const SecondaryFooter = ({ footerText, footerSubText, footerLink }) => {

    const { pathname } = useLocation();

    const { footerHeading, socialMedia, redirects, copyright } = categoryPageFooterData;

    const handleButtonClick = () => {
        window.location.href = 'mailto:info@sakuafresh.com';
    };

    return (
        <div className={`w-full bottom-0 right-0 mt-10 relative pb-3 md:pb-0`}>
            <div className=' w-fit h-fit absolute -top-[580px] left-0 z-0'>
                <img src={sakura} className='w-[400px]' alt="Sakura flowers" />
            </div>

            <div className='w-full flex justify-center items-center mt-20 mb-16 relative z-10'>
                <img src={ribbon} className='w-full max-w-12' alt="" />
            </div>
            <div className='w-full flex flex-col justify-center items-center relative'>
                <div className=''>
                    <Link to={footerLink ? footerLink : '#'}>
                        <h1 className='text-3xl md:text-4xl font-playfairDisplay font-[600] text-darkGreen tracking-tight'>{footerText ? footerText : footerHeading} <span className='font-manrope font-normal'>{`>`}</span> </h1>
                    </Link>
                    <p className='text-sm text-center mt-3 font-semibold'>{footerSubText ? footerSubText : ''}</p>
                </div>
                <div className='mt-'>
                    <img src={mountFuji} className='w-70' alt="" />
                </div>
                <div className='w-[90%] mx-auto h-[1px] rounded-sm bg-black relative'>
                    {/* {
                        !pathname.includes('contact-us') ? (
                            <SecondaryButton styles='absolute -top-14 right-0 text-sm font-semibold' buttonText={`Get in touch`} onClick={handleButtonClick} />
                        ) : ''
                    } */}
                </div>
            </div>
            <div className='w-[90%] flex flex-col md:flex-row pt-6 md:pt-4 justify-between items-center gap-6 md:gap-0 py-4 mx-auto text-darkBrown'>
                <div className='flex items-center gap-12 md:gap-6'>
                    {
                        socialMedia?.map((data) => (
                            <a key={data?.id} href={data?.link} target='blank'><img src={data?.icon} className={`${data?.id === 0 ? 'w-2.5' : 'w-4'}`} alt={data?.title} /></a>
                        ))
                    }
                </div>
                <div className='text-center'>
                    <p className='text-sm font-semibold'>{copyright}</p>
                </div>
            </div>
        </div>
    )
}

export default SecondaryFooter
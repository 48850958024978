import liquers from '../assets/images/non-alcoholics/liquers.webp'
import betterThanThis from '../assets/images/non-alcoholics/better-than-this.webp'
import aromaFinish from '../assets/images/non-alcoholics/aroma.webp'
import brixCommon from '../assets/images/non-alcoholics/brix2-common.webp'
import brixPremium from '../assets/images/non-alcoholics/brix2-premium.webp'
import tonicWater from '../assets/images/non-alcoholics/tonic-water.webp'
import supertarts from '../assets/images/non-alcoholics/supertarts.webp'

export const categoryPageData = [
    {
        title: 'Alcohols',
        awardWinners: [
            {
                id: 3,
                title: 'Liqeuers (zero alcohol)',
                image: liquers,
                slug: "zero-alcohol",
            },
            {
                id: 1,
                title: 'Better than bitters',
                image: betterThanThis,
                slug: "bitters",
            },
        ],
    },
    {
        title: 'Zero-Alcohols',
        awardWinners: [
            {
                id: 3,
                title: 'Zero Alcohol Spirits',
                image: liquers,
                slug: "zero-alcohol-spirits",
            },
            {
                id: 1,
                title: 'Bitters',
                image: betterThanThis,
                slug: "bitters",
            },
        ],
        allCollections: [
            {
                id: 6,
                title: 'Aroma finish',
                image: aromaFinish,
                slug: "aroma-finish",
            },
            {
                id: 4,
                title: 'common syrups',
                image: brixCommon,
                slug: "common-syrups",
            },
            {
                id: 5,
                title: 'premium syrups',
                image: brixPremium,
                slug: "premium-syrups",
            },
            {
                id: 0,
                title: 'Tonic concentrate',
                image: tonicWater,
                slug: "tonic-water-concentrates",
            },
            {
                id: 2,
                title: 'Supertarts',
                image: supertarts,
                slug: "super-tarts",
            },
        ],
    },
];
import React from 'react'
import flower from '../assets/icons/sakura-flowers.svg'
import SecondaryFooter from '../components/global/SecondaryFooter'
import CorporatePageHeader from '../components/global/CorporatePageHeader'
import ContactAddress from '../components/global/ContactAddress'

const ContactUs = () => {

    const handleButtonClick = () => {
        window.location.href = 'mailto:info@sakurafresh.com';
    };


    const addressData = [
        {
            id: 0,
            title: 'Japan HQ',
            address: [
                'Sakura Fresh Japan K.K.,',
                '2F, Nittochi Yamashitacho Building, 23 Yamashita-Cho, Naka-Ku,',
                'Yokohama, Japan, 231- 0023',
            ],
        },
        {
            id: 1,
            title: 'India',
            address: [
                'Sakurafresh Beverage and Food Ltd.,',
                '352/2, T.Bettahalli Village, Talkad Hobli, T.Narsipur Taluk, Mysore District,',
                'Karnataka, India, 571122',
            ],
        },
        {
            id: 2,
            title: 'Mauritius',
            address: [
                'Sakurafresh Beverage and Food Ltd.,',
                'Zone 5, MFD Freeport, Mer Rouge,',
                'Mauritius, 11609',
            ],
        },
    ];


    return (
        <div className='relative'>
            <div className='hidden md:block w-fit h-fit absolute top-0 right-0'>
                <img src={flower} className='w-[460px]' alt="Sakura flowers" />
            </div>
            <div className='pt-28 md:pt-32 px-10 md:px-20 lg:px-44 xl:px-72 relative z-10'>
                <CorporatePageHeader headerText={`Contact Us`} />
                <div className='w-full flex justify-center items-center'>
                    <h1 className='text-2xl md:text-3xl text-center font-'><span className='text-[#0000EE] hover:underline ml-1' onClick={handleButtonClick}>info@sakurafresh.com</span></h1>
                </div>
                <div className='mt-14 pb-8 max-w-[600px] text-center flex flex-col gap-14 md:gap-20 mx-auto'>
                    {
                        addressData?.map((data) => (
                            <ContactAddress key={data?.id} title={data?.title} address={data?.address} />
                        ))
                    }
                </div>
            </div>
            <SecondaryFooter footerText={`The Sakurafresh way`} footerLink={`/the-sakurafresh-way`} />
        </div>
    )
}

export default ContactUs
import alcohols from '../assets/images/alcohol.jpg'
import non_alcoholic from '../assets/images/non_alcohol.jpg'

export const landingpageData = [
    {
        id: 'alcohol',
        title: 'Alcohol',
        image: alcohols,
        active: false,
    },
    {
        id: 'zero-alcohol',
        title: 'Zero Alcohol',
        image: non_alcoholic,
        active: true,
    },
];
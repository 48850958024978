import React from 'react'
import PrimaryButton from '../global/PrimaryButton'
import { Link } from 'react-router-dom'

const AwardWinnerCategory = ({ image, title, id, textSize, onClick, ctaStyle, route, text }) => {

    return (
        <div className='flex-1 cursor-pointer group overflow-hidden relative bg-black' onClick={onClick}>
            <img src={image} className='w-full group-hover:scale-[300%] min-h-[35vh] max-h-auto sm:max-h-[30vh] xl:max-h-auto md:object-top xl:min-h-[75vh] group-hover: group-hover:opacity-50  group-hover:blur-xl transition-all duration-700 ease-in-out object-cover' alt="Cocktails" />
            <div className='w-full px-6 md:px-16 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                <h1 className={`hidden md:flex text-300 flex-col gap-3 opacity-0 group-hover:opacity-100 group-hover:text-600 group-hover:md:text-800 transition-all duration-700 ease-in-out w-full text-center font-playfairDisplaySC font-thin text-white group-hover:text-white`}>
                    {title?.split(' ')[0]}
                    <span>{title?.split(' ')[1]} {title?.split(' ')[2]}</span>
                </h1>
                <h1 className={`flex md:hidden flex-col gap-3 opacity-0 group-hover:opacity-100 text-[27px] leading-none transition-all duration-700 ease-in-out w-full text-center font-playfairDisplaySC font-thin text-white group-hover:text-white`}>
                    {title}
                </h1>
                <p className='mt-10 text-200 flex flex-col gap-3 opacity-0 group-hover:opacity-100 group-hover:leading-relaxed leading-relaxed group-hover:text-200 transition-all duration-700 ease-in-out w-full text-center font-manrope font-normal tracking-wide text-white group-hover:text-white'>{text}</p>
            </div>
            <Link to={`${route}`}>
                <PrimaryButton btnText={`EXPLORE CATEGORY`} styles={` opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out absolute bottom-7 md:bottom-14 left-1/2 -translate-x-1/2 invisible group-hover:visible text-center w-full sm:w-fit max-w-[201px] md:max-w-[220px] xl:max-w-[250px]`} />
            </Link>
        </div>
    )
}

export default AwardWinnerCategory
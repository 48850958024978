import React from 'react'
import LandingPagePreLoader from '../components/landingpage/LandingPagePreLoader'
import CategoryPannel from '../components/landingpage/CategoryPannel'
import { useNavigate } from 'react-router-dom'
import { landingpageData } from '../data/landingPageData'

const LandingPage = () => {
    
    const navigate = useNavigate();

    return (
        <div className='z-10 pt-28 md:pt-32'>
            <LandingPagePreLoader />
            <div className='w-full'>
                <div className='w-full flex flex-col-reverse lg:flex-row max-w-[93%] mx-auto gap-5 lg:gap-3'>
                    {
                        landingpageData?.map((data) => (
                            <CategoryPannel key={data?.id} id={data?.id} image={data?.image} title={data?.title} active={data?.active} onClick={() => data?.active ? navigate(`/category/${data?.id}`) : ''}/>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default LandingPage
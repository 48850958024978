import React from 'react'
import flower from '../assets/icons/sakura-flowers.svg'
import SecondaryFooter from '../components/global/SecondaryFooter'
import { sustainability } from '../data/sustainabilityData'
import CorporatePageHeader from '../components/global/CorporatePageHeader'

const SustainabilityPage = () => {

    const { intro, info, pointsFocus, conclusion } = sustainability;

    return (
        <div className='relative'>
            <div className='px-10 md:px-20 lg:px-44 xl:px-72'>

                <div className='hidden md:block w-fit h-fit absolute top-16 right-0'>
                    <img src={flower} className='w-[500px]' alt="Sakura flowers" />
                </div>
                <div className='w-full pt-28 md:pt-32'>
                    <CorporatePageHeader headerText={`Sustainability`} />
                    <h1 className='text-justify font-semibold'>{intro}</h1>
                    <div className='w-full flex flex-col gap-5 items-center justify-normal mt-12 mb-5 md:mb-10'>
                        {
                            info?.map((data) => {
                                if (data?.id === 0) {
                                    return (
                                        <div key={data?.id} className='flex flex-col md:flex-row items-center gap-5 md:gap-0'>
                                            <div className='w-full md:w-1/3 flex justify-start lg:justify-center'>
                                                <div className=''>
                                                    <img src={data?.image} className='w-full md:max-w-72 z-10 relative' alt="" />
                                                </div>
                                            </div>
                                            <div className='w-full h-full flex flex-col gap-12'>
                                                <div className='md:p-8 max-w-[800px]'>
                                                    <div className='mb-5 text-md'>
                                                        <p className={`gap- font-semibold text-3xl flex flex-col`}> <span>{data?.title?.split(' ')[0]} {data?.title?.split(' ')[1]}</span> <span>{data?.title?.split(' ')[2]} {data?.title?.split(' ')[3]} {data?.title?.split(' ')[4]} {data?.title?.split(' ')[5]} {data?.title?.split(' ')[6]}</span></p>
                                                        <span className='flex text-justify items-start pt-4 gap-1 font-semibold'>
                                                            {data?.content}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={data?.id} className='flex flex-col-reverse md:flex-row items-center gap-5 md:gap-0'>
                                            <div className='w-full h-full flex flex-col gap-12 md:text-right'>
                                                <div className='md:p-8 max-w-[800px]'>
                                                    <div className='mb-5 text-md'>
                                                        <p className={`gap- font-semibold text-3xl flex flex-col`}> <span>{data?.title?.split(' ')[0]}</span> <span>{data?.title?.split(' ')[1]} {data?.title?.split(' ')[2]} {data?.title?.split(' ')[3]} {data?.title?.split(' ')[4]} {data?.title?.split(' ')[5]} {data?.title?.split(' ')[6]}</span></p>
                                                        <span className='flex text-justify items-start pt-4 gap-1 font-semibold'>
                                                            {data?.content}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-full md:w-1/3 flex justify-start lg:justify-center'>
                                                <div className=''>
                                                    <img src={data?.image} className='w-full md:max-w-72 z-10 relative' alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                    <div className=''>
                        <h1 className='mb-5 font-semibold'>{pointsFocus?.title}</h1>
                    </div>
                    <div className='w-full'>
                        {
                            pointsFocus?.points?.map((data) => (
                                <div className='mb-5 text-md' key={data}>
                                    <p className={`gap-3 flex items-start font-semibold text-justify`}><span className='text-sm mt'>{`●`}</span> {data}</p>
                                </div>
                            ))
                        }
                    </div>
                    <div className='relative z-10'>
                        <h1 className='my-8 md:my-12 font-semibold text-justify'>{conclusion}</h1>
                    </div>
                </div>
            </div>
            <SecondaryFooter footerText={`Tancho Conservation`} footerLink={`/tancho`} />
        </div>
    )
}

export default SustainabilityPage
import image from '../assets/images/tancho/tancho-image.jpg'


export const tanchoData = {
    pageTitle: 'Tancho Conservation',
    video: 'https://image.sakurafresh.com/other+videos/Tancho+Crane+(final).mp4',
    intro: [
        `The Tancho ( 丹頂鶴 ), or Japanese Red-Crowned Crane, is a breathtakingly majestic bird native to Japan, renowned for the vibrant red skin atop its head. These birds, which breed in the Kushiro wetlands of Eastern Hokkaido, hold a cherished place in Japanese culture. They are celebrated for their elegant beauty and have long inspired traditional art, origami, and represent loyalty and longevity. Despite their revered status, the Tancho faced a dramatic decline, leading to their reclassification from "endangered" to "vulnerable".`,
        `Historically, the Tancho thrived across Japan, valued by shoguns during the Edo period. However, by the 20th century, rampant hunting by the burgeoning commoner population and the destruction of their wetland habitats due to urban development and agriculture brought these cranes to the brink of extinction. By the 1920s, the species was feared extinct until a small population was discovered in the Kushiro Marsh, surviving against the odds.`,
        `In response, the Japanese government took significant measures, including implementing hunting bans and restricting development in critical breeding areas, to protect these iconic birds. In 1935, the ministry of culture further reinforced this effort by declaring the Tancho a natural monument.`,
    ],
    role: {
        title: `Sakurafresh's Role in Tancho Conservation`,
        content: [
            `At Sakurafresh, we are deeply committed to contributing to the preservation of the Tancho and their natural habitats. Understanding the powerful role that awareness plays in conservation, we have embarked on a unique mission to integrate the story and imagery of the Tancho into our beverage products. By featuring images and illustrations of these magnificent cranes on our product packaging, we aim not only to celebrate their beauty but also to spark conversation and increase public awareness about their conservation.`,
            `Furthermore, we actively support wildlife bird photographers, empowering them to capture and share the Tancho's plight with the world. These visual storytellers play a crucial role in highlighting the challenges facing these cranes, including the threats posed by climate change to their wetland ecosystems. Through these efforts, we strive to illuminate the importance of preserving the natural environments where the Tancho forage, nest, and rear their young.`,
            `Sakurafresh believes that by weaving the story of the Tancho into the fabric of our brand, we can inspire our customers and the wider community to join us in our commitment to environmental stewardship and the protection of these symbols of natural heritage. Through our dedicated efforts, we hope to ensure that the Tancho continues to thrive for generations to come, a testament to the resilience of nature and the power of collective conservation action.`,
        ],
        image: image,
    },
};
export const sidebarData = [
    {
        id: 0,
        title: 'The Sakurafresh Way',
        route: '/the-sakurafresh-way',
    },
    {
        id: 1,
        title: 'About Us',
        route: '/about-us',
    },
    {
        id: 2,
        title: 'Global Spirits Awards',
        route: '/awards',
    },
    {
        id: 3,
        title: 'Our Collection',
        route: '/category/zero-alcohol',
    },
    {
        id: 4,
        title: 'Sustainability',
        route: '/sustainability',
    },
    {
        id: 5,
        title: 'Tancho Conservation',
        route: '/tancho',
    },
    {
        id: 6,
        title: 'Contact Us',
        route: 'contact-us',
    },
];
import React from 'react';

const Pagination = ({ currentPage, totalPages, onNext, onPrev }) => {
    return (
        <div className='flex justify-center items-center gap-8 text-sm font-bold'>
            <button className='disabled:text-gray-400 disabled:font-medium' onClick={onPrev} disabled={currentPage === 1}>Previous</button>
            <span>{currentPage} / {totalPages}</span>
            <button className='disabled:text-gray-400 disabled:font-medium' onClick={onNext} disabled={currentPage === totalPages}>Next</button>
        </div>
    );
};

export default Pagination;

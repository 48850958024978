import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { useScrollBlock } from "../../../hooks/useScrollBlock"
import './ArcWheelNav.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import sakura from '../../../assets/icons/sakura-flowers.svg'
import logo_small from '../../../assets/icons/logo-small.svg'
import PrimaryButton from '../PrimaryButton';


const ArcWheelNav = ({ routesArray, productSlug, enableBlockScroll }) => {

    const params = useParams()
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const defaultDeg = params?.id === 'zero-alcohol-spirits' ? 100 : params?.id === 'tonic-water-concentrates' ? 180 : params?.id === 'super-tarts' ? 340 : params?.id === 'common-syrups' ? 350 : params?.id === 'premium-syrups' ? 300 : pathname.includes('category') ? 150 : params?.id === 'zero-alcohol' ? 340 : params?.id === 'cocktail-premix' ? 80 : 180;
   

    const [itemDeg, setItemDeg] = useState(defaultDeg / routesArray.length)
    const [currentSelection, setCurrentSelection] = useState(Math.floor(routesArray.length / 2))
    const [blockScroll, allowScroll] = useScrollBlock();
    const [activeLinkIndex, setActiveLinkIndex] = useState(Math.floor(routesArray.length / 2))

    useEffect(() => {
        scrollToTop();
    }, [pathname]);


    const menuRotate = (rt, item) => {
        const $menu = $('.menu');
        const $menuItem = $('.menu').find('.item');

        $menuItem.removeClass('on');
        $menu.css('transform', 'rotate(' + rt + 'deg)');
        $menuItem.eq(item).addClass('on');



        if (item === 1 && currentSelection === 0) {
            scrollToTop();

            if (enableBlockScroll) {
                blockScroll();
            }
        }
    };

    useEffect(() => {
        if (currentSelection === 0) {
            allowScroll();
        } else {
        }
    }, [currentSelection])

    useEffect(() => {
        if (enableBlockScroll) {
            blockScroll();
        }
        const $menuItem = $('.menu').find('.item');

        $menuItem.each(function (r) {
            $(this).css('transform', 'rotate(' + (itemDeg / 2 - itemDeg * r) + 'deg) skew(' + (-90 + itemDeg) + 'deg)');
            $(this).children('.item-inner').css('transform', 'skew(' + (90 - itemDeg) + 'deg)');
            $(this).find('.txt').css('transform', 'rotate(' + (-itemDeg / 2) + 'deg)');
        });

        menuRotate(currentSelection * itemDeg, currentSelection)

        return () => { allowScroll() }
        // eslint-disable-next-line
    }, []);

    const handleClick = (item) => {
        setCurrentSelection(item)
        menuRotate(item * itemDeg, item)
        setActiveLinkIndex(item)
    }

    const handleWheel = (event) => {
        if (event.deltaY < 0 && currentSelection < routesArray.length - 1) {
            handleClick(currentSelection + 1)
        } else if (event.deltaY > 0 && currentSelection > 0) {
            handleClick(currentSelection - 1)
        }
    };

    const scrollToTop = () => {
        const duration = 1000;
        const startTime = performance.now();
        const startY = window.scrollY;

        const animateScroll = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const easeInOutCubic = t => t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
            window.scrollTo(0, easeInOutCubic(elapsedTime / duration) * (0 - startY) + startY);
            if (elapsedTime < duration) {
                requestAnimationFrame(animateScroll);
            }
        };

        requestAnimationFrame(animateScroll);
    };

    return (
        <div className='w-full h-screen relative overflow-hidden' onWheel={handleWheel}>
            <div className='w-full h-full flex relative'>
                <div className='w-full md:w-1/2 xl:w-3/5 h-full flex relative z-10'>
                    <div className='ArcWheelNav overflow-hidden w-full'>
                        <div className="menu-wrap absolute" style={{ left: '-10%' }}>
                            <ul className="menu absolute">
                                {routesArray?.map((item, index) => (
                                    <li className="item" id={index} key={index}>
                                        <div className="item-inner">
                                            <span className="logo-small txt text-3xl lg:text-600 xl:text-700 font-playfairDisplay uppercase cursor-pointer" onClick={() => handleClick(index)}>{item?.title}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className='absolute w-fit translate-x-28  xl:left-16 top-1/2 -translate-y-[80%] hidden lg:block'>
                        <img src={logo_small} className='w-10' alt="" />
                    </div>
                </div>
                <div className={`${pathname.includes('product') ? 'w-1/2' : ' w-2/5'} hidden md:flex justify-center xl:justify-start py-10 pl-6 z-10`}>
                    <div className={`w-fit flex flex-col ${pathname.includes('product') ? 'justify-center items-start' : 'justify-center items-center'}`}>
                        {/* <GlobalAwardsHeader /> */}
                        <div className={`w-full flex justify-center items-center h-[70vh] ${pathname.includes('category/zero-alcohol') ? '' : 'mb-8'}`}>
                            {
                                pathname.includes('product') ? (
                                    <div className='w-full flex flex-col justify-center items-center h-[70vh] mb-8 gap-8'>
                                        <div className='w-full flex justify-start'>
                                            {
                                                pathname.includes(`product`) && pathname.includes(`bitters`) ? <h1 className='txt text-3xl lg:text-700 font-playfairDisplay uppercase' alt="">{routesArray[activeLinkIndex]?.title}</h1> : <img src={routesArray[activeLinkIndex]?.productImage} className='w-full max-w-[80%] mx-auto md:max-w-[250px] xl:max-w-[300px]' alt="" />
                                            }
                                        </div>
                                        <div>
                                            {
                                                routesArray[activeLinkIndex]?.productDescription?.map((data) => (
                                                    <p className='max-w-[350px] xl:max-w-[500px] text-black text-justify font-semibold' key={data}>{data}</p>
                                                ))
                                            }
                                        </div>
                                        <div className='w-full flex items-center'>
                                            {
                                                pathname.includes('bitters') ?
                                                    <PrimaryButton btnText={`explore product`} styles={`uppercase font-bold tracking-wide`} onClick={() => navigate(`/${productSlug}/${routesArray[activeLinkIndex]?.slug}`)} />
                                                    : null
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <div className='w-full flex justify-center items-center h-[70vh] mb-8'>
                                        {params?.id === 'zero-alcohol-spirits' ? <img src={routesArray[activeLinkIndex]?.pinwheelImage} className='w-full max-w-44 xl:max-w-64 translate-y-5' alt="" /> : <img src={routesArray[activeLinkIndex]?.categoryImage} className='w-56 xl:w-64' alt="" />}
                                    </div>
                                )
                            }
                        </div>
                        {
                            pathname.includes('category') ?
                                <PrimaryButton btnText={`explore category`} textColor={``} onClick={() => navigate(`/${routesArray[activeLinkIndex]?.slug}`)} />
                                :
                                pathname.includes('zero-alcohol-spirits') ?
                                    <PrimaryButton btnText={`explore product`} textColor={`text-gold-700`} onClick={() => routesArray[activeLinkIndex]?.id === 'liq-05' ? navigate(`/cocktail/${routesArray[activeLinkIndex]?.slug}`) : routesArray[activeLinkIndex]?.id === 'liq-04' ? navigate(`/spirit/${routesArray[activeLinkIndex]?.slug}`) : navigate(`/liqueur/${routesArray[activeLinkIndex]?.slug}`)} />
                                    : null
                        }
                    </div>
                </div>
            </div>
            <div className='absolute right-0 top-[430px] -translate-y-1/3 z-0'>
                <img src={sakura} className='w-[500px]' alt="Sakura flowers" />
            </div>
        </div>
    );
}

export default ArcWheelNav;


import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ArcWheelNav from '../components/global/ArcWheelNav';
import { categories } from '../data/allCategories';
import PseudoPinWheelNav from '../components/global/SecondaryPseudoPinWheelNav';
import MobileCategoryCarousal from '../components/global/MobileCategoryCarousal';

const ProductPinwheelPage = () => {
    const[productSlug, setProductSlug] = useState("");
    const [routeData, setRouteData] = useState([]);
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1200);

    const params = useParams();

    const getArcWheelCategories = () => {
        let refinedCategories
        categories?.find((data) => {
            if (data?.slug == params?.id) {
                refinedCategories = data?.products;
            }
        });

        return refinedCategories
    }

    useEffect(() => {
        categories?.find((data) => {
            if (data?.slug == params?.id) {
                setRouteData(data?.products);
                setProductSlug(data?.slug)
            }
        });
    }, [params])

    useEffect(() => {
        const handleResize = () => {
          setIsWideScreen(window.innerWidth >= 1200);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <div>
            {isWideScreen ? (
                <ArcWheelNav routesArray={getArcWheelCategories()} enableBlockScroll={true} productSlug={productSlug} />
            ) : (
                routeData?.length ? <MobileCategoryCarousal routesArray={routeData} productSlug={productSlug} /> : null
            )}
        </div>
    )
}

export default ProductPinwheelPage

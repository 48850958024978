import React, { useEffect, useState } from 'react'

const ScrollToTopButton = ({ styles }) => {

    const [showButton, setShowButton] = useState();

    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const isScrollingUp = prevScrollPos > currentScrollPos;

            if (isScrollingUp && currentScrollPos > 800) {
                setShowButton(true);

                // Clear the previous timeout if it exists
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }

                // Set a timeout to hide the button after 3 seconds
                const id = setTimeout(() => {
                    setShowButton(false);
                }, 2000);

                setTimeoutId(id);
            } else {
                setShowButton(false);
                if (timeoutId) {
                    clearTimeout(timeoutId);
                    setTimeoutId(null);
                }
            }

            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos, timeoutId]);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    };

    return (
        <div className='w-full fixed bottom-4 px-4 flex justify-end items-center '>
            <button className={`${styles} z-50 cursor-pointer p-4 transition-all duration-500 ease-in-out ${showButton ? '' : 'translate-y-20'}`} onClick={handleScrollToTop}>
                <svg className="w-14 h-14 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m5 15 7-7 7 7" />
                </svg>
            </button>
        </div>
    )
}

export default ScrollToTopButton
import React, { useEffect, useState } from 'react'
import sakura from '../../assets/icons/sakura-flowers-2.svg'
import headingIcon from '../../assets/icons/heading-icon.svg'
import flower from '../../assets/icons/sakura-flowers.svg';
import sfway from '../../assets/icons/SF-way.svg'
import logo_main from '../../assets/icons/logo_main.svg'

const LandingPagePreLoader = () => {

    const [preLoaderActive, setPreLoaderActive] = useState(true);
    const [preLoaderData, setPreLoaderData] = useState(null);

    const chooseRandomElement = (arr) => {
        return arr[(Math.floor(Math.random() * arr.length))];
    }

    const loaderData = [
        {
            id: 0,
            title: `Sakurafresh's offerings span the entire spectrum of taste and style — light, delicate, and elegant to the rich, bold, and smoky.`,
        },
        {
            id: 1,
            title: `Sakurafresh is headquartered in the vibrant city of Yokohama, Japan.`,
        },
        {
            id: 2,
            title: `Shokunin Kishitsu - 'A Testament to Masterful Craftmanship & Unceasing Pursuit of Excellence'.`,
        },
        {
            id: 3,
            title: `Sakurafresh is deeply committed to contributing to the preservation of the Tancho and their natural habitats.`,
        },
    ];

    useEffect(() => {
        setPreLoaderData(chooseRandomElement(loaderData));
        setTimeout(() => {
            setPreLoaderActive(false);
        }, 3000);
    }, []);


    return (
        <div className={`w-full z-50 fixed top-0 left-0 right-0 bg-white overflow-hidden ${!preLoaderActive ? 'h-0' : 'h-screen'} duration-500 transition-all ease-in-out`}>
            <div className='hidden md:block w-fit h-fit absolute -top-44 left-0 z-0'>
                <img src={sakura} className='w-[380px]' alt="Sakura flowers" />
            </div>
            <div className='hidden md:block w-fit h-fit absolute top-56 md:top-[45%] 2xl:top-72 right-0'>
                <img src={flower} className='w-[400px] relative z-0' alt="Sakura flowers" />
            </div>
            <div className='w-full min-h-screen flex flex-col justify-center items-center relative'>
                <div className='w-full max-w-[600px] flex items-center flex-col gap-8 px-10 md:px-0 -translate-y-20 md:-translate-y-10'>
                    <img src={logo_main} alt='Main Logo' className={`w-full max-w-64 z-20 animate-pulse duration-1000 transition-all ease-in-out`} />
                    <h1 className='text-md leading-6 text-center font-manrope font-semibold text-gray-400'>{preLoaderData?.title}</h1>
                </div>
            </div>
            <div className='w-full absolute bottom-32 md:bottom-20 flex justify-center items-center'>
                <img src={headingIcon} className='w-8 md:w-7 mt-8 animate-spin' alt="Sakura flowers" />
            </div>
        </div>
    )
}

export default LandingPagePreLoader
import React from 'react';
import { Link } from 'react-router-dom';
import WrappedText from '../components/global/WrappedText';

const replaceTextWithLink = (text, searchTerm, link) => {
    const parts = text.split(searchTerm);
    return (
        <>
            {parts.map((part, index) => (
                <React.Fragment key={index}>
                    {part}
                    {index < parts.length - 1 && <Link className='p-0.5' to={link}> <WrappedText text={searchTerm} /> </Link>}
                </React.Fragment>
            ))}
        </>
    );
};

export default replaceTextWithLink;
